import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ForgetPassword } from '../../service/service';

function ForgotPassword({ setStep,setEmailData }) {
    const [email, setEmail] = useState('');
    const [loading, setloading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const FormData = {
            email,
            is_admin: true
        }
        setloading(true)
        ForgetPassword(FormData)
            .then((result) => {

                if (result.status) {
                    setErrorMsg('')
                    // console.log(result.data);
                    setEmailData(result.data)
                    setStep(1)
                    setloading(false)
                }
                else {
                    setErrorMsg(result.message)
                    setloading(false)
                }

            })
            .catch((error) => {
                console.log(error.message);
                setErrorMsg(error.message)
                setloading(false)
            });


    };
    return (
        <div className='w-full'>
            <div className='max-w-[611px] mx-auto px-2 xs:px-4 sm:px-14 space-y-4'>
                <div>
                    <div className='w-fit mx-auto p-4 bg-[#E1E9F2] rounded-full'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                            <path d="M12.2812 17.1562C12.799 17.1562 13.2188 16.7365 13.2188 16.2188C13.2188 15.701 12.799 15.2812 12.2812 15.2812C11.7635 15.2812 11.3438 15.701 11.3438 16.2188C11.3438 16.7365 11.7635 17.1562 12.2812 17.1562Z" fill="#186CCE" />
                            <path d="M15.4219 22.125H3.75C2.71613 22.125 1.875 21.2838 1.875 20.25V12.5625C1.875 11.5286 2.71613 10.6875 3.75 10.6875H17.25C17.4635 10.6875 17.6328 10.7063 17.7678 10.745C18.2655 10.8876 18.7846 10.5997 18.9273 10.1019C19.0699 9.60422 18.782 9.08512 18.2843 8.94248C17.979 8.85502 17.6407 8.8125 17.25 8.8125H16.1233V5.50622C16.1233 2.47008 13.5999 0 10.4983 0C7.39664 0 4.87327 2.47008 4.87327 5.50622V8.8125H3.75C1.68225 8.8125 0 10.4948 0 12.5625V20.25C0 22.3177 1.68225 24 3.75 24H15.4219C15.9397 24 16.3594 23.5803 16.3594 23.0625C16.3594 22.5447 15.9397 22.125 15.4219 22.125ZM6.74827 5.50622C6.74827 3.50395 8.43052 1.875 10.4983 1.875C12.566 1.875 14.2483 3.50395 14.2483 5.50622V8.8125H6.74827V5.50622Z" fill="#186CCE" />
                            <path d="M8.76562 17.1562C9.28339 17.1562 9.70312 16.7365 9.70312 16.2188C9.70312 15.701 9.28339 15.2812 8.76562 15.2812C8.24786 15.2812 7.82812 15.701 7.82812 16.2188C7.82812 16.7365 8.24786 17.1562 8.76562 17.1562Z" fill="#186CCE" />
                            <path d="M22.5051 15.5255C22.4846 13.7072 20.999 12.2344 19.176 12.2344C17.3403 12.2344 15.8467 13.7279 15.8467 15.5637C15.8467 16.0815 16.2664 16.5012 16.7842 16.5012C17.302 16.5012 17.7217 16.0815 17.7217 15.5637C17.7217 14.7618 18.3741 14.1094 19.176 14.1094C19.978 14.1094 20.6303 14.7618 20.6303 15.5637C20.6303 15.5722 20.6304 15.5807 20.6306 15.5891C20.621 16.1632 20.2761 16.6771 19.7464 16.9033C18.8304 17.2944 18.2385 18.1979 18.2385 19.2049V20.0156C18.2385 20.5334 18.6582 20.9531 19.176 20.9531C19.6938 20.9531 20.1135 20.5334 20.1135 20.0156V19.2049C20.1135 18.95 20.2585 18.7234 20.4827 18.6277C21.7121 18.1029 22.5062 16.9001 22.5059 15.5635C22.5059 15.5507 22.5056 15.5381 22.5051 15.5255Z" fill="#186CCE" />
                            <path d="M19.1719 24C19.6896 24 20.1094 23.5803 20.1094 23.0625C20.1094 22.5447 19.6896 22.125 19.1719 22.125C18.6541 22.125 18.2344 22.5447 18.2344 23.0625C18.2344 23.5803 18.6541 24 19.1719 24Z" fill="#186CCE" />
                            <path d="M5.25 17.1562C5.76777 17.1562 6.1875 16.7365 6.1875 16.2188C6.1875 15.701 5.76777 15.2812 5.25 15.2812C4.73223 15.2812 4.3125 15.701 4.3125 16.2188C4.3125 16.7365 4.73223 17.1562 5.25 17.1562Z" fill="#186CCE" />
                        </svg>
                    </div>
                </div>
                <div className='text-2xl font-bold leading-[28px] text-center'>
                    Forgot Password?
                </div>
                <div className='text-primaryDark text-lg font-normal leading-[28px] text-center'>
                    Enter the Email address associated with your account and we’ll send you a OTP to reset your password
                </div>
                <form className="space-y-[14px]" onSubmit={handleSubmit}>
                    {/* Email input */}
                    <div className='h-[85px] rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                        <label htmlFor="email" className="block text-sm font-normal leading-5 text-primaryDark">
                            Email
                        </label>
                        <div className="">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={handleChange}
                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                            />
                        </div>
                    </div>
                    {errorMsg !== '' && <p className="text-red-500 px-3">{errorMsg}</p>}

                    {/* Submit button */}
                    <div className='pt-[10px]'>
                        <button
                            type="submit"
                            disabled={loading}
                            className="flex w-full h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                        >
                            Send
                        </button>
                    </div>
                </form>
                <hr className='h-[1px] bg-[#D2D6DC]' />
                <div className='text-center text-lg font-semibold'>
                    <span className='text-[#515F7B] mr-1'>
                        Remember password?
                    </span>
                    <Link
                        to="/admin"
                        className="text-primaryBlue">
                        Login
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword