import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Banner from '../../components/banner/Banner'
import Footer from '../../components/footer/Footer'
import img from '../../assets/profile-img-placeholder.png'
import { EditUserInfo } from '../../service/service'
import Swal from 'sweetalert2'
function ProfilePage() {
    const userData = JSON.parse(localStorage.getItem('loginData'))
    const [name, setName] = useState('');
    useEffect(() => {
        setName(userData.name ?? '')
    }, [])

    const submitData = () => {
        EditUserInfo({ name }).then((result)=>{
            if(result.status){
                const data ={
                    ...userData,
                    name
                }
                localStorage.setItem('loginData', JSON.stringify(data));
                Swal.fire({
                    icon: 'success',
                    title: result.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }
    return (
        <>
            <Header />
            <Banner title={'Profile'} />
            <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                    <div>
                        <div className='flex flex-col md:flex-row gap-4 sm:gap-6'>
                            <div className='flex justify-center'>
                                <figure className='w-[120px] h-[120px] sm:w-[175px] sm:h-[175px]'>
                                    <img src={img} alt="Avtar" />
                                </figure>
                            </div>
                            <div className='space-y-4 sm:space-y-6 flex-1'>
                                <div className='text-2xl sm:text-4xl font-extrabold text-center md:text-left'>{userData?.name}</div>
                                <div className='flex flex-col w-full sm:flex-row justify-between gap-4 sm:gap-6'>
                                    <div className='h-[85px] w-full rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                                        <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                            {`Full Name`}
                                        </label>
                                        <div className="">
                                            <input
                                                id="full-name"
                                                name="name"
                                                type="text"
                                                placeholder='Simon Adam'
                                                autoComplete="name"
                                                // required
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>
                                    <div className='h-[85px] w-full rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                                        <label htmlFor="email" className="block text-sm font-normal leading-5 text-primaryDark">
                                            {`Email address`}
                                        </label>
                                        <div className="">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder='simon123@gmail.com'
                                                autoComplete="email"
                                                // required
                                                value={userData.email}
                                                readOnly
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>
                                   
                                </div>
                                <div className='flex justify-center items-center flex-col-reverse sm:flex-row gap-4 sm:gap-6'>
                                        <button
                                            type="button"
                                            // disabled={loading}
                                            // onClick={submitData}
                                            className="flex w-full sm:max-w-[193px] border-[#E41D1D] border-[1px] h-12 items-center justify-center rounded-3xl bg-transparent hover:bg-opacity-[0.9]  px-3 py-1.5 text-base font-normal leading-7 text-[#E41D1D] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                        >
                                            Delete Account
                                        </button>
                                        <button
                                            type="button"
                                            // disabled={loading}
                                            onClick={submitData}
                                            className="flex w-full sm:max-w-[193px] h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                        >
                                            Update Information
                                        </button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ProfilePage