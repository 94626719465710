import { Dialog, Listbox, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { DropdownIcon } from '../../../assets/icon-file'
import { MultiSelect } from 'react-multi-select-component'

export default function SubscribeModal({ isOpen, setIsOpen, emailList, handleSubmit, loading, selected, setSelected }) {

  function closeModal() {
    setIsOpen(false)
  }



  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[99999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[411px] transform rounded-2xl bg-white px-8 pt-4 pb-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-bold leading-[normal] flex justify-between items-center text-primaryDark"
                >
                  Select Email List
                  <span
                    onClick={closeModal}
                    className='cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M1 13L13 1M1 1L13 13" stroke="#64748B" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Dialog.Title>
                <form className='pt-6 space-y-4' onSubmit={handleSubmit}>

                  {

                    <>
                      <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                        <label htmlFor="role" className="block text-sm font-normal leading-5 text-primaryDark">
                          Email list*
                        </label>
                        <div className="subscribe-list">
                          {/* <Listbox value={selected} onChange={setSelected}>
                              <div className="relative">
                                <Listbox.Button className="relative w-full cursor-pointer h-[43px] bg-transparent py-2 pr-10 text-left focus:outline-none sm:text-lg sm:leading-normal font-semibold">
                                  <span className="block truncate">{selected?.audience_name}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <DropdownIcon
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {emailList.map((roles, roleIdx) => (
                                      <Listbox.Option
                                        key={roleIdx}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 px-4 ${active ? 'bg-primaryBlue text-whiteGrey' : 'text-gray-900'
                                          }`
                                        }
                                        value={roles}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${selected ? 'font-semibold' : 'font-semibold'
                                                }`}
                                            >
                                              {roles.audience_name}
                                            </span>
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox> */}
                          <MultiSelect
                            options={emailList}
                            value={selected}
                            onChange={setSelected}
                            labelledBy="Select"
                          />
                        </div>
                      </div>


                    </>
                  }



                  <div className="mt-4 flex justify-center gap-4 items-center">
                    <button
                      type="button"
                      className="w-[146px] h-[45px] py-2 bg-transparent border-[1px] border-primaryBlue text-primaryBlue rounded-[61px] transition duration-300"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      disabled={loading}
                      className="w-[146px] h-[45px] py-2 text-white bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue rounded-[61px] transition duration-300 disabled:opacity-50"
                    >
                      Submit
                    </button>
                  </div>

                </form>



              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
