import React from "react";
// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const LoginRoute = ({ children }) => {
    // const userData = useSelector((state) => state.userdata.userdata)
    const userData = JSON.parse(localStorage.getItem("loginData"));
    // console.log(userData)
  if (!userData) {
    return children
  }
  else {
    return <Navigate to={"/"} />
  }

};

export default LoginRoute;