import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../assets/aboutpic2.png';


function Section2() {
    return (
        <section id='about-section-2' className='px-2 xs:px-5 py-8 md:py-[60px] bg-[#F2F2F2]'>
            <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
                <div className='flex flex-col lg:flex-row items-center gap-8'>
                    <div className="lg:w-1/2">
                        <h2 className='text-primaryDark leading-[normal] font-extrabold text-2xl md:text-4xl mb-4'>About Inside Chassidus
                        </h2>
                        <div className='text-primaryDark leading-[normal] font-semibold text-base xs:text-lg space-y-3 text-justify'>
                            <p>
                                Our goal is to provide resources for studying Chassidic philosophy from the inside – inside the text and the original sources. The audio archive includes individual lectures, short series, and extensive detailed series taught at an advanced level. We hope that students of Chassidus at all levels will find the archive inspiring and educational.
                            </p>

                        </div>
                        <div className='flex flex-col gap-4 pt-8'>
                            <strong className='text-[22px] font-bold leading-[normal]'>
                                Links to other websites:
                            </strong>
                            <ul className='list-disc text-primaryBlue text-base xs:text-lg font-semibold underline ml-5 space-y-6'>
                                <li>
                                    <Link
                                        target="_blank"
                                        to="https://maamarimonline.org">
                                        https://maamarimonline.org
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        target="_blank"
                                        to="http://stumptherabbi.org/">
                                        http://stumptherabbi.org/
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        target="_blank"
                                        to="https://www.theyeshiva.net/">
                                        https://www.theyeshiva.net/
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="lg:w-1/2">
                        <div>
                            <img src={img} alt="Chassidus" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section2