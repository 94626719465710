import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import Swal from 'sweetalert2'
import { AddTagType, AdminListTagsType,  UpdateAdminTagType } from '../../service/service';
import { Tooltip } from "react-tooltip";

function TagsTypeModal(
    {
        isOpen, setIsOpen, setResponseData, formData, setFormData, isEdit,
        DropdownOptions,
        currentPage, itemsPerPage, setTotalPages,
        formErrors, setFormErrors
    }
) {

 
    const [loading, setloading] = useState(false);

    function closeModal() {
        setIsOpen(false)
    }
    const handleChange = (event) => {

        const { name, value } = event.target;

            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        

    };
 
    const handleSubmit = async (event) => {
        event.preventDefault();
       
        setFormErrors({
            name: '',
        });
        if (validateForm()) {

            try {
                setloading(true)
                let result = '';
                if (isEdit) {

                    const data = {
                        id: formData.id,
                        name: formData.name,
                       
                    }
                    result = await UpdateAdminTagType(data);

                }
                else {
                    const data = {
                        name: formData.name,
                      
                    }
                    result = await AddTagType(data);

                }
                setIsOpen(false)
                if (result.status) {
                    setloading(false)
                    setFormData({
                       
                        tag_type: '',
                    
                    });
                    setFormErrors({
                    
                        tag_type: '',
                       
                    })
                    AdminListTagsType(currentPage, itemsPerPage)
                        .then((result) => {
                            if (result.status) {
                                setResponseData(result.data.data)
                                const totalPages = Math.ceil(result.data.total / itemsPerPage);
                                setTotalPages(totalPages)
                            }
                            else {
                                Swal.fire({
                                    title: 'Error!',
                                    text: result.message,
                                    icon: 'error',
                                    timer: 3000,
                                    confirmButtonText: 'OK'
                                })
                            }

                        })

                    Swal.fire({
                        icon: 'success',
                        title: result.message,
                        showConfirmButton: false,
                        timer: 1500
                    }
                    )
                }
                else {
                    setloading(false)
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }
            }
            catch (error) {
                setIsOpen(false)
                console.log(error.message);
                setloading(false)
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            }
        }
    
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!formData.name) {
            isValid = false;
            errors.name = 'Name is required';
        }
   


        setFormErrors(errors);
        return isValid;
    };

 
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[99999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[411px] transform  rounded-2xl bg-whiteGrey px-8 pt-4 pb-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-bold leading-[normal] flex justify-between items-center text-primaryDark"
                                    >
                                        {isEdit ? 'Edit Tag' : 'Add New Tag Type'}
                                        <span
                                            onClick={closeModal}
                                            className='cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M1 13L13 1M1 1L13 13" stroke="#64748B" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </Dialog.Title>
                                    <form className='pt-6 space-y-4' onSubmit={handleSubmit}>
                                        <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                            <div className='flex gap-x-1 items-center'>
                                                <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                                    Name

                                                </label>
                                                {/* <InformationCircleIcon
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="The name is how it appears on your site."
                                                    className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                                    aria-hidden="true"
                                                /> */}
                                            </div>
                                            <div className="">
                                                <input
                                                    id="full-name"
                                                    name="name"
                                                    
                                                    value={formData?.name}
                                                    onChange={handleChange}
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder='Enter new tag type'
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        {formErrors?.name && <div className="text-red-500 px-2 !mt-1">{formErrors?.name}</div>}


                                        {/* Submit button */}

                                        <div className="mt-4 flex justify-center gap-4 items-center">
                                            <button
                                                type="button"
                                                className="w-[146px] h-[45px] py-2 bg-transparent border-[1px] border-primaryBlue text-primaryBlue rounded-[61px] transition duration-300"
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>

                                            <button
                                                type="submit"
                                                disabled={loading}
                                                className="w-[146px] h-[45px] py-2 text-whiteGrey bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue rounded-[61px] transition duration-300 disabled:opacity-50"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>



                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                    <Tooltip
                        id="my-tooltip"
                        place="bottom-start"
                        style={{ backgroundColor: "#161E2E", color: "#F9F9F9", width: '231px' }}
                    />
                </Dialog>
            </Transition>
        </>
    )
}

export default TagsTypeModal