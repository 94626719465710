import { Fragment, useState, useRef, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react';
import { AdminListSeries, CategoryOrderList, CategorySetOrderList, CreateAdminseries, LogoutUser, SeriesOrderList, UpdateAdminseries } from '../../service/service';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdminUser, updateAdminUser } from '../../store/slices/AdminUserSlice';
import Swal from 'sweetalert2'
import EditorSideNav from '../EditorSideNav/EditorSideNav';
import { formatJewishDate, toJewishDate } from 'jewish-date';
import DragableTable from '../../ReuseableComponents/DragableTable';

function NewSeriesEditor({ setShowEditor, setIsLoading, isEdit,showSeriesList }) {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.adminUser.userData);
    const formData = useSelector((state) => state.adminUser.editorFormData);
    const [featueFile, setfeatueFile] = useState(null);
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [items, setItems] = useState([]);
    const [innerLoading, setInnerLoading] = useState(false);

    useEffect(() => {
        ListOrderTable()
    }, []);
    const ListOrderTable=()=>{
        if (isEdit) {
            setInnerLoading(true)
            SeriesOrderList({ id: formData.id }).then((result) => {
                const sortedData = result.list.sort((a, b) => parseInt(a.order_sequence) - parseInt(b.order_sequence));
                setItems(sortedData);
                setInnerLoading(false)
            }).catch((error) => {
                console.log(error)
                setInnerLoading(false)
            });
        }
    }
    const SubmitOrder = () => {
        setloading(true)
        const data = items.map((obj, index) => {
            return {
                parent_id: formData.id,
                child_id: obj.id,
                type: obj.type,
                order_sequence: index + 1
            }
        })
        CategorySetOrderList({ list: data }).then((result) => {
            setloading(false)
            if(result.status){
                Swal.fire({
                    icon: 'success',
                    title: result.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                ListOrderTable()
            }
            
        })
    }
    function SignOutIcon(props) {
        return (

            <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
            </svg>

        )
    }

    const textareaRef = useRef(null);

    const TextAreaAutoResize = (e) => {
        const textarea = textareaRef.current;
        textarea.style.height = '150px';
        const scrollHeight = textarea.scrollHeight;
        textarea.style.height = `${scrollHeight}px`;
    };
    const handleSignOut = () => {
        const token = localStorage.getItem('adminToken');
        LogoutUser(token).then((result) => {
           
            localStorage.removeItem('adminToken');
            localStorage.removeItem('adminData');
            navigate("/admin");
            dispatch(logoutAdminUser())
        })
            .catch((error) => {
                console.log(error.message);

            });

    };
    const CreateNewSeries = (is_draft) => {
        setIsLoading(true)
       
        const Form_Data = new FormData();
        if(formData.date){
            const date = new Date(formData.date);
            const jewishDate = toJewishDate(date);
            
            const jewishDateInEnglish = formatJewishDate(jewishDate);

            Form_Data.append('hebrew_date', jewishDateInEnglish);
        }
        Form_Data.append('title', formData.title);
        Form_Data.append('description', formData.description);
        if(is_draft){
            Form_Data.append('is_publish', 0);
            Form_Data.append('is_draft', 1);
        }
        else{
            Form_Data.append('is_publish', formData.is_publish);
            Form_Data.append('is_draft', formData.is_draft);
        }
        Form_Data.append('date', formData.date);
        Form_Data.append('time', formData.time);
        Form_Data.append('visibility', formData.visibility);
        Form_Data.append('custom_permalink', formData.perma_link);
        Form_Data.append('categories', JSON.stringify(formData.categories));
        Form_Data.append('series', JSON.stringify(formData.series));
        Form_Data.append('tags', JSON.stringify(formData.tags));
        Form_Data.append('feature_image', (featueFile === null ||featueFile ==='null' ) ?'': featueFile);
        CreateAdminseries(Form_Data).then((result) => {
            if (result.status) {
             
                if(is_draft){
                    
                    showSeriesList('Series drafted successfully')
                }
                else{

                    showSeriesList('Series created successfully')
                }
            }
            else {
                setIsLoading(false)
                Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            }
        })
            .catch((error) => {
                setIsLoading(false)
                console.log(error.message);
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            });
    }
    const UpdateSeries = () => {
        setIsLoading(true)
        const Form_Data = new FormData();
        if(formData.date){
            const date = new Date(formData.date);
            const jewishDate = toJewishDate(date);
            
            const jewishDateInEnglish = formatJewishDate(jewishDate);

            Form_Data.append('hebrew_date', jewishDateInEnglish);
        }
        Form_Data.append('id', formData.id);
        Form_Data.append('title', formData.title);
        Form_Data.append('description', formData.description);
        Form_Data.append('is_publish', formData.is_publish);
        Form_Data.append('is_draft', formData.is_draft);
        Form_Data.append('date', formData.date?? '');
        Form_Data.append('time', formData.time ?? '');
        Form_Data.append('visibility', formData.visibility);
        Form_Data.append('custom_permalink', formData.perma_link);
        Form_Data.append('slug', formData.slug);
        Form_Data.append('categories', JSON.stringify(formData.categories));
        Form_Data.append('series', JSON.stringify(formData.series));
        Form_Data.append('tags', JSON.stringify(formData.tags));
        Form_Data.append('feature_image', (featueFile === null ||featueFile ==='null' ) ?'': featueFile);
        UpdateAdminseries(Form_Data).then((result) => {
            if (result.status) {
          
                showSeriesList('Series updated successfully')
            }
            else {
                setIsLoading(false)
                Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            }
        })
            .catch((error) => {
                setIsLoading(false)
                console.log(error.message);
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            });
    }
    const handleChange = (event) => {

        const { name, value } = event.target;

            dispatch(updateAdminUser({
                editorFormData: {
                    ...formData,
                    [name]: value
                }
            }));
           
       

    }
    return (
        <>
            <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
                <div className='px-[22px] sm:px-8 bg-whiteGrey'>
                    <div className='flex justify-between items-center text-primaryDark sm:h-[72px] text-left'>
                        <div className='flex justify-center items-center gap-3 text-2xl leading-[21px] font-semibold'>
                            <div className='hidden sm:block'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M4 6.5H14.5M4 12.5H20M4 18.5H14.5" stroke="#161E2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <span>
                                Classes
                            </span>

                        </div>
                        <div className='flex justify-between items-center gap-x-5'>
                     
                            <div className="relative flex">
                                <Menu>
                                    {({ open }) => (
                                        <>
                                            <Menu.Button >
                                                <div className='flex gap-x-3 items-center justify-center'>
                                                    <div className='flex items-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        </svg>

                                                    </div>
                                                    <div className='text-base font-semibold leading-[21px] text-primaryDark'>
                                                        {userData?.name}
                                                    </div>
                                                    <div className='flex items-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M10.25 15.6365C10.1163 15.6365 9.98251 15.5854 9.88045 15.4833L4.65319 10.2561C4.44894 10.0518 4.44894 9.72109 4.65319 9.51696C4.85745 9.31284 5.1882 9.31271 5.39232 9.51696L10.25 14.3746L15.1077 9.51696C15.312 9.31271 15.6427 9.31271 15.8468 9.51696C16.051 9.72122 16.0511 10.052 15.8468 10.2561L10.6196 15.4833C10.5175 15.5854 10.3837 15.6365 10.25 15.6365Z" fill="#161E2E" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </Menu.Button>

                                            {open && (
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        static
                                                        className="z-10 bg-white border w-56 border-gray-300 absolute right-0 top-8 mt-2 rounded-md shadow-lg focus:outline-none"
                                                    >
                                                        <div className="px-1 py-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        className={`${active ? 'bg-primaryDark text-whiteGrey' : 'text-primaryDark'
                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-base font-semibold`}
                                                                        onClick={handleSignOut}
                                                                    >
                                                                        {active ? (
                                                                            <SignOutIcon
                                                                                className="mr-2 h-5 w-5 text-whiteGrey"
                                                                                aria-hidden="true"
                                                                            />
                                                                        ) : (
                                                                            <SignOutIcon
                                                                                className="mr-2 h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        )}
                                                                        Log Out
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            )}
                                        </>
                                    )}
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <hr className='bg-[#D2D6DC] h-[1px]' />
                    <div className='flex justify-between items-center text-primaryDark h-[76px] text-left sm:border-b-[1px]'>
                        <div
                            onClick={() => setShowEditor(false)}
                            className='flex justify-center items-center gap-2 cursor-pointer text-lg lg:text-xl leading-[21px] font-semibold'>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.052 19.9968C17.337 19.6829 17.3113 19.1993 16.9947 18.9168L10.9244 13.5L16.9947 8.08324C17.3113 7.80069 17.337 7.31712 17.052 7.00317C16.767 6.68923 16.2793 6.66378 15.9627 6.94633L9.25534 12.9315C9.09281 13.0766 9 13.2832 9 13.5C9 13.7168 9.09281 13.9234 9.25534 14.0685L15.9627 20.0537C16.2793 20.3362 16.767 20.3108 17.052 19.9968Z" fill="#161E2E" />
                                </svg>
                            </span>

                            <span>
                                Back to Series
                            </span>
                        </div>
                        <div className='text-xl font-bold'>
                            {isEdit ? "Edit":"Add New"}  Series
                        </div>
                        <div>
                            {isEdit ?
                                <button
                                    type="button"
                                    onClick={UpdateSeries}
                                    className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                >
                                    Update Series
                                </button>
                                :
                               <div className='flex gap-x-2'>
                                 <button
                                    type="button"
                                    onClick={()=>CreateNewSeries(true)}
                                    className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                >
                                    Draft Series
                                </button>
                                <button
                                    type="button"
                                    onClick={()=>CreateNewSeries(false)}
                                    className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                >
                                    Publish Series
                                </button>
                               </div>
                            }
                        </div>
                        {/* {headerButton} */}
                    </div>
                </div>
                <div className='pr-10 pl-[31px] py-8 flex min-h-screen gap-x-10'>
                    <div className='min-w-[385px] w-[35%] bg-whiteGrey rounded-lg'>
                        <EditorSideNav
                        setfeatueFile={setfeatueFile}
                        formData={formData}
                        selected={selected}
                        setSelected={setSelected}
                        tabtext={'Series'}
                        />
                    </div>
                    <div className='flex-grow max-w-[65%] space-y-6'>
                        <div className='bg-whiteGrey rounded-lg h-[85px] p-4'>
                            <label htmlFor="title" className="block text-base font-bold leading-5 text-primaryDark">
                                Add series title
                            </label>
                            <div className="">
                                <input
                                    id="title"
                                    name="title"
                                    type="text"
                                    autoComplete="off"
                                    required
                                    value={formData.title}
                                    placeholder='write here...'
                                    onChange={handleChange}
                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                />
                            </div>
                        </div>
                        <div className='bg-whiteGrey rounded-lg min-h-[200px] p-4'>
                            <label htmlFor="description" className="block text-base font-bold leading-5 text-primaryDark">
                                Add series description
                            </label>
                            <div className="">
                                <textarea
                                    id="description"
                                    name="description"
                                    placeholder='write here...'
                                    type="text"
                                    autoComplete="off"
                                    required
                                    value={formData.description}
                                    ref={textareaRef}
                                    onInput={TextAreaAutoResize}
                                    onChange={handleChange}
                                    className="h-[150px] block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                />
                            </div>
                        </div>
                       {
                       isEdit && items.length>0 &&
                       <button
                            type="button"
                            onClick={SubmitOrder}
                            disabled={loading}
                            className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                        >
                            Save Order
                        </button>
                        }
                        <DragableTable
                            items={items}
                            setItems={setItems}
                            loading={innerLoading}
                        />
                    </div>
                </div>
            </main>
           
        </>

    )
}

export default NewSeriesEditor