import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/header/Header';
import Banner from '../../components/banner/Banner';
import { useEffect } from 'react';
import { GetDataFromURL } from '../../service/service';
import { useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import Footer from '../../components/footer/Footer';
// import NotFound from '../notfoundPage/NotFound';
import { FacebookIcon, FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from 'react-share';
import NotFound from '../notfoundPage/NotFound';
import { Helmet } from 'react-helmet';
const shareUrl = window.location.href;
function SearchSeries() {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false)
    const { uniqueName } = useParams();
    const [items, setItems] = useState([]);
    const [title, setTitle] = useState("Inside Chassidus | Audio Classes | Chassidus for Everyone")
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    
        // Optionally, you can also add a listener for navigation changes
        const handleScrollToTop = () => {
          window.scrollTo(0, 0);
        };
    
        // Attach the listener to the "beforeunload" event
        window.addEventListener('beforeunload', handleScrollToTop);
    
        // Clean up the listener when the component unmounts
        return () => {
          window.removeEventListener('beforeunload', handleScrollToTop);
        };
      }, []);
    useEffect(() => {
        const data = {
            type: 'series',
            node: uniqueName
        }
        setLoading(true)
        GetDataFromURL(data).then((result) => {
            setLoading(false)
            if (result.status) {
                // console.log(result.data[0])
                setData(result.data)
                setTitle(`${result.data?.title ?? result.data?.name} | Inside Chassidus`)
                // const sortedData = result?.data?.list.sort((a, b) => parseInt(a.order_sequence) - parseInt(b.order_sequence));
                setItems(result?.data?.list);

            }
        })
    }, [uniqueName])

    const OpenClass = (obj) => {

        const data = obj;
        navigate(`/${data.custom_permalink ?? data.slug}`);
    }
    const OpenSeries = (obj) => {

        const data = obj;
        navigate(`/series/${data.perma_link ?? data.slug}`);

    }
    return (
        <>
            <Header />
            <Banner title={data?.title} description={data?.description} />
            <Helmet>
            <title>{title}</title>
            </Helmet>
            {
                loading ?
                    <div className='flex justify-center min-h-[50vh]'>
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                        />
                    </div>
                    :
                    
                    <>
                    {
                        data?
                        <>
                         {
                            items?.length > 0 ?

                                <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                                    <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                                        {
                                            items?.html &&
                                            <div className='html-block'>

                                                <div dangerouslySetInnerHTML={{ __html: items?.html }} />
                                            </div>
                                        }
                                        <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                                        {
                                            items?.map((obj) => (
                                                <div key={obj.id}>

                                                    {
                                                        obj.type === 'series' &&
                                                        <>
                                                            {/* <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                                                                Series
                                                            </div> */}
                                                            {/* <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'> */}
                                                                <div onClick={() => OpenSeries(obj)} className='cursor-pointer rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                                                    <div className='pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                                                        <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] px-6 bg-whiteGrey flex items-center justify-center rounded-lg'>
                                                                            <span>

                                                                                <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                                                                    <path d="M5.89655 15H36.3103C37.0786 15 37.8153 15.3052 38.3585 15.8484C38.9017 16.3916 39.2069 17.1283 39.2069 17.8966V35.2759C39.2069 36.0441 38.9017 36.7808 38.3585 37.324C37.8153 37.8672 37.0786 38.1724 36.3103 38.1724H5.89655C5.12834 38.1724 4.39159 37.8672 3.84838 37.324C3.30517 36.7808 3 36.0441 3 35.2759V17.8966C3 17.1283 3.30517 16.3916 3.84838 15.8484C4.39159 15.3052 5.12834 15 5.89655 15Z" fill="#161E2E" />
                                                                                    <path d="M25.4494 25.4119L17.9375 21.0719C17.7228 20.9448 17.4782 20.8769 17.2287 20.875C16.8366 20.875 16.4604 21.0308 16.1831 21.3081C15.9058 21.5854 15.75 21.9616 15.75 22.3537V31.6681C15.75 31.9284 15.8198 32.1839 15.952 32.408C16.0843 32.6322 16.2741 32.8169 16.5019 32.9428C16.7297 33.0687 16.987 33.1314 17.2472 33.1242C17.5073 33.117 17.7608 33.0402 17.9813 32.9019L25.5019 28.1506C25.7357 28.0043 25.9277 27.7998 26.059 27.5573C26.1903 27.3147 26.2566 27.0422 26.2513 26.7664C26.246 26.4906 26.1694 26.2208 26.0288 25.9835C25.8883 25.7461 25.6887 25.5492 25.4494 25.4119Z" fill="#D2D6DC" />
                                                                                    <path d="M10.138 3.62067H31.8621C32.2462 3.62067 32.6146 3.77325 32.8862 4.04486C33.1578 4.31646 33.3104 4.68484 33.3104 5.06894C33.3104 5.45305 33.1578 5.82142 32.8862 6.09303C32.6146 6.36463 32.2462 6.51722 31.8621 6.51722H10.138C9.75387 6.51722 9.38549 6.36463 9.11389 6.09303C8.84228 5.82142 8.6897 5.45305 8.6897 5.06894C8.6897 4.68484 8.84228 4.31646 9.11389 4.04486C9.38549 3.77325 9.75387 3.62067 10.138 3.62067Z" fill="#161E2E" />
                                                                                    <path d="M7.24149 9.41382H34.7587C35.1428 9.41382 35.5112 9.5664 35.7828 9.83801C36.0544 10.1096 36.207 10.478 36.207 10.8621C36.207 11.2462 36.0544 11.6146 35.7828 11.8862C35.5112 12.1578 35.1428 12.3104 34.7587 12.3104H7.24149C6.85738 12.3104 6.48901 12.1578 6.2174 11.8862C5.9458 11.6146 5.79321 11.2462 5.79321 10.8621C5.79321 10.478 5.9458 10.1096 6.2174 9.83801C6.48901 9.5664 6.85738 9.41382 7.24149 9.41382Z" fill="#161E2E" />
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-primaryDark'>
                                                                            <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title}</div>
                                                                            <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{obj.description != null ? obj.description : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex items-center justify-center pr-4 sm:pr-9 gap-x-4 sm:gap-x-8'>

                                                                    </div>
                                                                </div>
                                                            {/* </div> */}
                                                        </>
                                                    }
                                                    {
                                                        obj.type === 'class' &&
                                                        <>
                                                            {/* <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                                                                Classes
                                                            </div> */}
                                                            {/* <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'> */}

                                                                <div
                                                                    onClick={() => OpenClass(obj)}
                                                                    className='cursor-pointer rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                                                    <div className='pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                                                        <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] px-6 bg-whiteGrey flex items-center justify-center rounded-lg'>
                                                                            <span>
                                                                                <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                                                    <g clipPath="url(#clip0_326_587)">
                                                                                        <path d="M25.3333 20V5.33334C25.3324 5.13306 25.2864 4.93557 25.1987 4.75552C25.111 4.57548 24.9838 4.4175 24.8267 4.29334C24.6718 4.16803 24.4908 4.07903 24.2969 4.03289C24.1031 3.98674 23.9014 3.98461 23.7067 4.02667L11.7067 6.69334C11.4104 6.76014 11.1459 6.92607 10.9568 7.16368C10.7676 7.40128 10.6653 7.69633 10.6667 8V19.12C9.75645 18.6903 8.73143 18.5673 7.74539 18.7695C6.75934 18.9717 5.86545 19.4882 5.19779 20.2414C4.53014 20.9947 4.12473 21.9441 4.04238 22.9473C3.96004 23.9505 4.20519 24.9533 4.74107 25.8054C5.27695 26.6574 6.07467 27.3127 7.01454 27.673C7.95441 28.0333 8.98576 28.0791 9.95388 27.8036C10.922 27.5281 11.7747 26.9461 12.384 26.1449C12.9933 25.3437 13.3264 24.3665 13.3333 23.36C13.3471 23.1291 13.3471 22.8976 13.3333 22.6667V9.06667L22.6667 7V16.4533C21.7559 16.0234 20.7303 15.9005 19.7438 16.1031C18.7572 16.3058 17.8631 16.823 17.1956 17.5772C16.528 18.3313 16.1232 19.2817 16.0419 20.2855C15.9606 21.2894 16.2071 22.2925 16.7445 23.1443C17.2819 23.996 18.0812 24.6505 19.0222 25.0093C19.9632 25.3681 20.9953 25.4119 21.9634 25.1342C22.9314 24.8564 23.7833 24.2721 24.3909 23.4689C24.9986 22.6657 25.3293 21.6871 25.3333 20.68C25.346 20.4535 25.346 20.2265 25.3333 20Z" fill="#161E2E" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_326_587">
                                                                                            <rect width="32" height="32" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-primaryDark'>
                                                                            <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title}</div>
                                                                            <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{obj?.description}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex items-center justify-center pr-4 sm:pr-9 gap-x-4 sm:gap-x-8'>

                                                                    </div>
                                                                </div>

                                                            {/* </div> */}
                                                        </>
                                                    }
                                                </div>
                                            ))
                                        }
                                        </div>
                                        {/* {
                                            data?.class?.length > 0 &&
                                            <>
                                                <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                                                    Classes
                                                </div>
                                                <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                                                    {
                                                        data?.class?.map((obj) => (
                                                            <div key={obj.id}
                                                                onClick={() => OpenClass(obj)}
                                                                className='cursor-pointer rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                                                <div className='pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                                                    <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] px-6 bg-whiteGrey flex items-center justify-center rounded-lg'>
                                                                        <span>
                                                                            <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                                                <g clipPath="url(#clip0_326_587)">
                                                                                    <path d="M25.3333 20V5.33334C25.3324 5.13306 25.2864 4.93557 25.1987 4.75552C25.111 4.57548 24.9838 4.4175 24.8267 4.29334C24.6718 4.16803 24.4908 4.07903 24.2969 4.03289C24.1031 3.98674 23.9014 3.98461 23.7067 4.02667L11.7067 6.69334C11.4104 6.76014 11.1459 6.92607 10.9568 7.16368C10.7676 7.40128 10.6653 7.69633 10.6667 8V19.12C9.75645 18.6903 8.73143 18.5673 7.74539 18.7695C6.75934 18.9717 5.86545 19.4882 5.19779 20.2414C4.53014 20.9947 4.12473 21.9441 4.04238 22.9473C3.96004 23.9505 4.20519 24.9533 4.74107 25.8054C5.27695 26.6574 6.07467 27.3127 7.01454 27.673C7.95441 28.0333 8.98576 28.0791 9.95388 27.8036C10.922 27.5281 11.7747 26.9461 12.384 26.1449C12.9933 25.3437 13.3264 24.3665 13.3333 23.36C13.3471 23.1291 13.3471 22.8976 13.3333 22.6667V9.06667L22.6667 7V16.4533C21.7559 16.0234 20.7303 15.9005 19.7438 16.1031C18.7572 16.3058 17.8631 16.823 17.1956 17.5772C16.528 18.3313 16.1232 19.2817 16.0419 20.2855C15.9606 21.2894 16.2071 22.2925 16.7445 23.1443C17.2819 23.996 18.0812 24.6505 19.0222 25.0093C19.9632 25.3681 20.9953 25.4119 21.9634 25.1342C22.9314 24.8564 23.7833 24.2721 24.3909 23.4689C24.9986 22.6657 25.3293 21.6871 25.3333 20.68C25.346 20.4535 25.346 20.2265 25.3333 20Z" fill="#161E2E" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_326_587">
                                                                                        <rect width="32" height="32" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <div className='text-primaryDark'>
                                                                        <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title}</div>
                                                                        <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{obj.description}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex items-center justify-center pr-4 sm:pr-9 gap-x-4 sm:gap-x-8'>

                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {
                                            data?.sub_series?.length > 0 &&
                                            <>
                                                <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                                                    Series
                                                </div>
                                                <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                                                    {
                                                        data?.sub_series?.map((obj) => (
                                                            <div key={obj.id} onClick={() => OpenSeries(obj)} className='cursor-pointer rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                                                <div className='pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                                                    <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] px-6 bg-whiteGrey flex items-center justify-center rounded-lg'>
                                                                        <span>

                                                                            <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                                                                <path d="M5.89655 15H36.3103C37.0786 15 37.8153 15.3052 38.3585 15.8484C38.9017 16.3916 39.2069 17.1283 39.2069 17.8966V35.2759C39.2069 36.0441 38.9017 36.7808 38.3585 37.324C37.8153 37.8672 37.0786 38.1724 36.3103 38.1724H5.89655C5.12834 38.1724 4.39159 37.8672 3.84838 37.324C3.30517 36.7808 3 36.0441 3 35.2759V17.8966C3 17.1283 3.30517 16.3916 3.84838 15.8484C4.39159 15.3052 5.12834 15 5.89655 15Z" fill="#161E2E" />
                                                                                <path d="M25.4494 25.4119L17.9375 21.0719C17.7228 20.9448 17.4782 20.8769 17.2287 20.875C16.8366 20.875 16.4604 21.0308 16.1831 21.3081C15.9058 21.5854 15.75 21.9616 15.75 22.3537V31.6681C15.75 31.9284 15.8198 32.1839 15.952 32.408C16.0843 32.6322 16.2741 32.8169 16.5019 32.9428C16.7297 33.0687 16.987 33.1314 17.2472 33.1242C17.5073 33.117 17.7608 33.0402 17.9813 32.9019L25.5019 28.1506C25.7357 28.0043 25.9277 27.7998 26.059 27.5573C26.1903 27.3147 26.2566 27.0422 26.2513 26.7664C26.246 26.4906 26.1694 26.2208 26.0288 25.9835C25.8883 25.7461 25.6887 25.5492 25.4494 25.4119Z" fill="#D2D6DC" />
                                                                                <path d="M10.138 3.62067H31.8621C32.2462 3.62067 32.6146 3.77325 32.8862 4.04486C33.1578 4.31646 33.3104 4.68484 33.3104 5.06894C33.3104 5.45305 33.1578 5.82142 32.8862 6.09303C32.6146 6.36463 32.2462 6.51722 31.8621 6.51722H10.138C9.75387 6.51722 9.38549 6.36463 9.11389 6.09303C8.84228 5.82142 8.6897 5.45305 8.6897 5.06894C8.6897 4.68484 8.84228 4.31646 9.11389 4.04486C9.38549 3.77325 9.75387 3.62067 10.138 3.62067Z" fill="#161E2E" />
                                                                                <path d="M7.24149 9.41382H34.7587C35.1428 9.41382 35.5112 9.5664 35.7828 9.83801C36.0544 10.1096 36.207 10.478 36.207 10.8621C36.207 11.2462 36.0544 11.6146 35.7828 11.8862C35.5112 12.1578 35.1428 12.3104 34.7587 12.3104H7.24149C6.85738 12.3104 6.48901 12.1578 6.2174 11.8862C5.9458 11.6146 5.79321 11.2462 5.79321 10.8621C5.79321 10.478 5.9458 10.1096 6.2174 9.83801C6.48901 9.5664 6.85738 9.41382 7.24149 9.41382Z" fill="#161E2E" />
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <div className='text-primaryDark'>
                                                                        <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title}</div>
                                                                        <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{obj.description != null ? obj.description : ''}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex items-center justify-center pr-4 sm:pr-9 gap-x-4 sm:gap-x-8'>

                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        } */}
                                        <div className='font-bold text-xl'>
                                            Share this series!
                                        </div>
                                        <div className='flex items-center gap-x-[27px] mt-3'>
                                            <WhatsappShareButton
                                                url={shareUrl}
                                                separator=":: "
                                                className="Demo__some-network__share-button"
                                            >
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                            <FacebookShareButton
                                                url={shareUrl}
                                                className="Demo__some-network__share-button"
                                            >
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>

                                            <TwitterShareButton
                                                url={shareUrl}
                                                className="Demo__some-network__share-button"
                                            >
                                                <XIcon size={32} round />
                                            </TwitterShareButton>
                                        </div>
                                    </div>
                                </section>
                                :

                                <div className='text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]'>
                                    No Data To Show
                                </div>
                        }
                        </>:
                        <NotFound/>
                    }
                       
                    </>
            }
            <Footer />
        </>
    )
}

export default SearchSeries