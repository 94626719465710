import React from 'react';
import img from '../../assets/aboutpic1.png';
import imgSmall from '../../assets/aboutpic_small.png';
import { Link } from 'react-router-dom';

function Section1() {
    return (
        <section id='about-section-1' className='px-2 xs:px-5 pt-4 pb-[40px] md:pt-8 md:pb-[60px]'>
            <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
                <div className='flex flex-col lg:flex-row items-center gap-8'>
                    <div className='lg:w-1/3'>
                        <img src={img} alt="Rabbi Yossi Paltie" className='hidden lg:block md:max-h-[527px]' />
                        <img src={imgSmall} alt="Rabbi Yossi Paltie" className='lg:hidden' />
                        
                            </div>
                            <div className='lg:w-2/3 '>
                                <h2 className='text-primaryDark leading-[normal] font-extrabold text-2xl md:text-4xl mb-4 md:mb-6'>About Rabbi Paltiel</h2>
                                <div className='text-primaryDark leading-[normal] font-semibold text-base xs:text-lg space-y-3 text-justify'>
                                    <p>
                                        Rabbi Yossi Paltiel, Founder of InsideChassidus.org, is a popular teacher and mentor with a gift for communicating his passion for Torah and Judaism.
                                    </p>
                                    <p>
                                        His classes weave together classic commentaries, Jewish Law, history and philosophy, personal stories, and a deep knowledge of Chassidus and Kabbala into a whole that’s both intellectually challenging and heartwarming.
                                    </p>
                                    <p>
                                        He currently teaches at Yeshivas Tomchei Temimim Chovevei Torah (United Lubavitcher Yeshiva), Machon L’Yahadus Women’s Institute for the Study of Judaism, and at the Beis Midrash L’Nashim (Women’s and Girls’ Beis Midrash and Seminary for Higher Learning under the Directorship of Rebbetzin Ariella Benhiyoun) at 770 Eastern Parkway, Crown Heights, Brooklyn.
                                    </p>
                                    <p>
                                        He also gives lectures at many events in Brooklyn and travels to speak in Jewish communities throughout the country.
                                    </p>
                                </div>
                                <div className='flex flex-col sm:flex-row gap-4 pt-8'>
                                    <Link
                                        to='/contact-us'
                                        className="flex w-fit h-10 sm:h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-4 sm:px-8 py-[14px] text-[14px] sm:text-base font-bold leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                    >
                                        Contact Rabbi Paltiel
                                    </Link>
                                    {/* <Link
                                        to="#"
                                        className="max-w-[270px] xs:max-w-[unset] flex w-fit h-10 sm:h-12 items-center justify-center rounded-3xl text-primaryBlue border-[1px] border-primaryBlue hover:bg-opacity-[0.9] px-2 xs:px-4 sm:px-8 py-[14px] text-[14px] sm:text-base font-bold leading-7 bg-transparent shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                    >
                                        <span className='truncate'>
                                            Sitemap for all of Rabbi Paltiel’s classes.
                                        </span>
                                    </Link> */}
                                </div>
                            </div>
                    </div>
                </div>
        </section>
        
    )
}

export default Section1