import React from "react";
import { Navigate } from "react-router-dom";

const DashboardRoute = ({ children }) => {
  const user = localStorage.getItem("adminToken");
  if (!user) {
    return children
  }
  else {
    const userData = JSON.parse(localStorage.getItem("adminData"));
    if (userData?.roles.length > 0) {
      const userPermissions = userData?.roles[0].permissions.map(
        (item) => item.name
      );

      if (userPermissions?.includes("view_class")) {

        return <Navigate to={"/page-builder"} />
      }
      else if (userPermissions?.includes("view_class")) {
        return <Navigate to={"/class-management"} />
      }
      else if (userPermissions?.includes("view_category")) {
        return <Navigate to={"/class-management/categories"} />
      }
      else if (userPermissions?.includes("view_tags")) {
        return <Navigate to={"/class-management/tags"} />
      }
      else if (userPermissions?.includes("view_series")) {
        return <Navigate to={"/class-management/series"} />
      }
      else if (userPermissions?.includes("view_user")) {
        return <Navigate to={"/user-management"} />
      }
      else if (userPermissions?.includes("view_role")) {
        return <Navigate to={"/role-management"} />
      }
      else if (userPermissions?.includes("view_permission")) {
        return <Navigate to={"/permission-management"} />
      }
      else if (userPermissions?.includes("view_slider")) {
        return <Navigate to={"/content-management"} />
      }
      else if (userPermissions?.includes("view_gallery")) {
        return <Navigate to={"/content-management/media-library"} />
      }
      else if (userPermissions?.includes("view_question")) {
        return <Navigate to={"/content-management/questions"} />
      }
      else if (userPermissions?.includes("view_team")) {
        return <Navigate to={"/content-management/team"} />
      }
      else if (userPermissions?.includes("view_comment")) {
        return <Navigate to={"/content-management/contact-us"} />
      }
      else if (userPermissions?.includes("view_menu")) {
        return <Navigate to={"/menu-management"} />
      }
      else {
        return <Navigate to={"/unauthorized"} />
      }
    }
  }

};

export default DashboardRoute;