import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { Combobox } from "@headlessui/react";
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import Swal from 'sweetalert2'
import { AddAdminTag, AddTagType, AdminListTags, UpdateAdminTag } from '../../service/service';
import { Tooltip } from "react-tooltip";
import { DropdownIcon } from '../../assets/icon-file';

function TagsModal(
    {
        isOpen, setIsOpen, setResponseData, formData, setFormData, isEdit,
        DropdownOptions, setDropdownOptions,
        currentPage, itemsPerPage, setTotalPages,
        formErrors, setFormErrors
    }
) {
    const inputRef = useRef(null);
    const [query, setQuery] = useState("");
    const [newTagType, setNewTagType] = useState("");
    const [addTagBtn, setAddTagBtn] = useState(true);
    const filteredPeople =
        query === ""
            ? DropdownOptions
            : DropdownOptions.filter((person) =>
                person.name
                    .toLowerCase()
                    .replace(/\s+/g, "")
                    .includes(query.toLowerCase().replace(/\s+/g, ""))
            );

    const [loading, setloading] = useState(false);

    function closeModal() {
        setIsOpen(false)
        setAddTagBtn(true)
    }
    const handleChange = (event) => {

        const { name, value } = event.target;
        if (name === 'name') {
            setFormData((prevData) => ({
                ...prevData,
                slug: value.replace(/(?:\s*-\s*)|(\s+)/g, (match, group) => {
                    return group ? '-' : '';
                }).toLowerCase()
            }));
        }
        if (name === 'slug') {
            setFormData((prevData) => ({
                ...prevData,
                slug: value.replace(/\s/g, '-').toLowerCase()
            }));
        }
        else {

            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }

    };
    const handleDropDown = (data) => {
        setFormData((prevData) => ({
            ...prevData,
            tag_type: data, // Update the corresponding field in your form data
        }));
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(formData)
        setFormErrors({
            name: '',
            slug: '',
            description: '',
            tag_type: '',
            permalink: ''
        });
        if (validateForm()) {

            try {
                setloading(true)
                let result = '';
                if (isEdit) {

                    const data = {
                        id: formData.id,
                        name: formData.name,
                        slug: formData.slug,
                        description: formData.description,
                        tag_type: formData.tag_type?.id === null ? null : formData.tag_type?.name,
                        perma_link: formData.permalink
                    }
                    result = await UpdateAdminTag(data);

                }
                else {
                    const data = {
                        name: formData.name,
                        slug: formData.slug,
                        description: formData.description,
                        tag_type: formData.tag_type?.id === null ? null : formData.tag_type?.name,
                        perma_link: formData.permalink
                    }
                    result = await AddAdminTag(data);

                }
                setIsOpen(false)
                if (result.status) {
                    setloading(false)
                    setFormData({
                        name: '',
                        slug: '',
                        description: '',
                        tag_type: {},
                        permalink: ''
                    });
                    setFormErrors({
                        name: '',
                        slug: '',
                        description: '',
                        tag_type: '',
                        permalink: ''
                    })
                    AdminListTags(currentPage, itemsPerPage)
                        .then((result) => {
                            if (result.status) {
                                setResponseData(result.data.data)
                                const totalPages = Math.ceil(result.data.total / itemsPerPage);
                                setTotalPages(totalPages)
                            }
                            else {
                                Swal.fire({
                                    title: 'Error!',
                                    text: result.message,
                                    icon: 'error',
                                    timer: 3000,
                                    confirmButtonText: 'OK'
                                })
                            }

                        })

                    Swal.fire({
                        icon: 'success',
                        title: result.message,
                        showConfirmButton: false,
                        timer: 1500
                    }
                    )
                }
                else {
                    setloading(false)
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }
            }
            catch (error) {
                setIsOpen(false)
                console.log(error.message);
                setloading(false)
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            }
        }
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!formData.name) {
            isValid = false;
            errors.name = 'Name is required';
        }
        if (!formData.slug) {
            isValid = false;
            errors.slug = 'Slug is required';
        }
        if (Object.keys(formData.tag_type).length === 0) {
            isValid = false;
            errors.tag_type = 'Tag type is required';
        }
        // if (!formData.permalink) {
        //     isValid = false;
        //     errors.permalink = 'Permalink is required';
        // }


        setFormErrors(errors);
        return isValid;
    };
    const [rndId, setRndId] = useState(-1)
    const addNewType = () => {
        if (newTagType !== '') {
            setAddTagBtn(true)
            formData.tag_type = { id: rndId, name: newTagType };
            // console.log(formData)
            AddTagType({ name: newTagType }).then((result) => {
                if (result.status) {
                    setDropdownOptions([...DropdownOptions, { id: rndId, name: newTagType }])
                    setRndId(rndId - 1)
                    setNewTagType('')
                }
            })
        }
    }
    const showTagtypeInput =()=>{
        setAddTagBtn(false)
        focusInput()
    }
    const focusInput = () => {
    
        setTimeout(() => {
            inputRef.current.focus();
        }, 50);
        
      };
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[99999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[411px] transform  rounded-2xl bg-whiteGrey px-8 pt-4 pb-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-bold leading-[normal] flex justify-between items-center text-primaryDark"
                                    >
                                        {isEdit ? 'Edit Tag' : 'Add New Tag'}
                                        <span
                                            onClick={closeModal}
                                            className='cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M1 13L13 1M1 1L13 13" stroke="#64748B" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </Dialog.Title>
                                    <form className='pt-6 space-y-4' onSubmit={handleSubmit}>
                                        <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                            <div className='flex gap-x-1 items-center'>
                                                <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                                    Name

                                                </label>
                                                <InformationCircleIcon
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="The name is how it appears on your site."
                                                    className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="">
                                                <input
                                                    id="full-name"
                                                    name="name"
                                                    type="text"
                                                    autoComplete="name"
                                                    required
                                                    value={formData?.name}
                                                    onChange={handleChange}
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        {formErrors?.name && <div className="text-red-500 px-2 !mt-1">{formErrors?.name}</div>}
                                        <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                            <div className='flex gap-x-1 items-center'>
                                                <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                                    Slug
                                                </label>
                                                <InformationCircleIcon
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens."
                                                    className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="">
                                                <input
                                                    id="slug"
                                                    name="slug"
                                                    type="text"
                                                    autoComplete="off"
                                                    required
                                                    value={formData?.slug}
                                                    onChange={handleChange}
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        {formErrors?.slug && <div className="text-red-500 px-2 !mt-1">{formErrors?.slug}</div>}
                                        <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                            <div className='flex gap-x-1 items-center'>
                                                <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                                    Tag Type
                                                </label>
                                                <InformationCircleIcon
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Categories, unlike tags, can have a hierarchy. You might have a Jazz category, and under that have children categories for Bebop and Big Band. Totally optional."
                                                    className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="">
                                                <Combobox value={formData?.tag_type} onChange={handleDropDown}>
                                                    <div className="relative">
                                                        <Combobox.Input
                                                            className="w-full border-none h-[43px] bg-transparent py-1.5 pr-10 text-left focus:outline-none sm:text-lg sm:leading-normal font-semibold"
                                                            displayValue={(person) => person.name}
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                        <Combobox.Button 
                                                        onClick={focusInput}
                                                        className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <DropdownIcon
                                                                aria-hidden="true"
                                                            />
                                                        </Combobox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                            afterLeave={() => setQuery('')}
                                                        >
                                                            <Combobox.Options className="absolute mt-1 h-52 w-full overflow-auto rounded-md bg-whiteGrey py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {filteredPeople.length === 0 && query !== '' ? (

                                                                    <div className="relative text-center cursor-default select-none py-2 px-4 text-gray-700">
                                                                        Nothing found.
                                                                    </div>


                                                                ) : (
                                                                    <>
                                                                        {
                                                                            filteredPeople.map((person) => (
                                                                                <Combobox.Option
                                                                                    key={person.id}
                                                                                    className={({ active }) =>
                                                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primaryBlue text-whiteGrey' : 'text-primaryDark'
                                                                                        }`
                                                                                    }
                                                                                    value={person}
                                                                                >
                                                                                    {({ selected, active }) => (
                                                                                        <>
                                                                                            <span
                                                                                                className={`block truncate ${selected ? 'font-semibold' : 'font-semibold'
                                                                                                    }`}
                                                                                            >
                                                                                                {person.name}
                                                                                            </span>
                                                                                            {selected ? (
                                                                                                <span
                                                                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-whiteGrey' : 'text-primaryBlue'
                                                                                                        }`}
                                                                                                >
                                                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </>
                                                                                    )}
                                                                                </Combobox.Option>
                                                                            ))
                                                                        }
                                                                    </>
                                                                )}
                                                                <div className='flex justify-center bg-whiteGrey rounded-b-md ring-1 ring-black ring-opacity-5 fixed w-full max-w-[315px] bottom-[68.5px] py-4'>
                                                                    {/* {
                                                                        addTagBtn ? */}
                                                                            <button
                                                                                type="button"
                                                                                className={`${addTagBtn ? 'flex' :'hidden'} w-[146px] py-1 justify-center items-center gap-x-2 bg-transparent border-[1px] border-primaryBlue text-primaryBlue rounded-[61px] transition duration-300`}
                                                                                // onClick={() => }
                                                                                onClick={showTagtypeInput}
                                                                            >
                                                                                Add Tag Type
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                                                    <path d="M11.9 5.4H7.10004V0.599963C7.10004 0.268835 6.8312 0 6.49996 0C6.16884 0 5.9 0.268835 5.9 0.599963V5.4H1.09996C0.768835 5.4 0.5 5.66884 0.5 5.99996C0.5 6.3312 0.768835 6.60004 1.09996 6.60004H5.9V11.4C5.9 11.7312 6.16884 12 6.49996 12C6.8312 12 7.10004 11.7312 7.10004 11.4V6.60004H11.9C12.2312 6.60004 12.5 6.3312 12.5 5.99996C12.5 5.66884 12.2312 5.4 11.9 5.4Z" fill="#186CCE" />
                                                                                </svg>
                                                                            </button>
                                                                            {/* // : */}
                                                                            <div className={`${addTagBtn ? 'hidden' :'flex'} w-full px-4 gap-x-2 justify-between items-center`}>
                                                                                <div className='h-[39px] w-full rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] px-4'>
                                                                                    <input
                                                                                        id="tagtype"
                                                                                        name="tagtype"
                                                                                        type="text"
                                                                                        ref={inputRef}
                                                                                        autoComplete="off"
                                                                                        placeholder='Enter new tag type'
                                                                                        onChange={(e) => setNewTagType(e.target.value)}
                                                                                        value={newTagType}
                                                                                        className="block h-full w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-sm sm:leading-normal font-semibold"
                                                                                    />
                                                                                </div>
                                                                                <button
                                                                                    className='p-3 bg-primaryBlue text-whiteGrey rounded-lg h-[39px]'
                                                                                    onClick={addNewType}
                                                                                    type="button"
                                                                                >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                                        <path d="M3.7929 14.293L10.0858 8.0001L3.7929 1.7072C3.60725 1.51927 3.50349 1.26552 3.50429 1.00136C3.50509 0.737196 3.61039 0.48408 3.79718 0.297288C3.98397 0.110495 4.23709 0.00520102 4.50125 0.00439914C4.76542 0.00359726 5.01917 0.107353 5.20709 0.293008L12.2071 7.29301C12.3946 7.48054 12.5 7.73489 12.5 8.0001C12.5 8.26531 12.3946 8.51966 12.2071 8.7072L5.20709 15.7072C5.01917 15.8929 4.76542 15.9966 4.50125 15.9958C4.23709 15.995 3.98397 15.8897 3.79718 15.7029C3.61039 15.5161 3.50509 15.263 3.50429 14.9988C3.50349 14.7347 3.60725 14.4809 3.7929 14.293Z" fill="#F9F9F9" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                    {/* } */}
                                                                </div>
                                                            </Combobox.Options>
                                                        </Transition>
                                                    </div>
                                                </Combobox>

                                            </div>
                                        </div>
                                        {formErrors?.tag_type && Object.keys(formData?.tag_type).length === 0 && <div className="text-red-500 px-2 !mt-1">{formErrors.tag_type}</div>}
                                        <div className='h-[135px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                            <div className='flex gap-x-1 items-center'>
                                                <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                                    Description
                                                </label>
                                                <InformationCircleIcon
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="The description is not prominent by default; however, some themes may show it."
                                                    className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="">
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    type="text"
                                                    rows={3}
                                                    autoComplete="off"
                                                    // required
                                                    value={formData?.description}
                                                    onChange={handleChange}
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        {formErrors?.description && <div className="text-red-500 px-2 !mt-1">{formErrors?.description}</div>}
                                        <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                            <div className='flex gap-x-1 items-center'>
                                                <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                                    Custom Permalink
                                                </label>
                                                <InformationCircleIcon
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="add an additional link to be used to see this category directly"
                                                    className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="">
                                                <input
                                                    id="permalink"
                                                    name="permalink"
                                                    type="text"
                                                    autoComplete="off"
                                                    // required
                                                    value={formData?.permalink}
                                                    onChange={handleChange}
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        {formErrors?.permalink && <div className="text-red-500 px-2 !mt-1">{formErrors?.permalink}</div>}


                                        {/* Submit button */}

                                        <div className="mt-4 flex justify-center gap-4 items-center">
                                            <button
                                                type="button"
                                                className="w-[146px] h-[45px] py-2 bg-transparent border-[1px] border-primaryBlue text-primaryBlue rounded-[61px] transition duration-300"
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>

                                            <button
                                                type="submit"
                                                disabled={loading}
                                                className="w-[146px] h-[45px] py-2 text-whiteGrey bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue rounded-[61px] transition duration-300 disabled:opacity-50"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>



                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                    <Tooltip
                        id="my-tooltip"
                        place="bottom-start"
                        style={{ backgroundColor: "#161E2E", color: "#F9F9F9", width: '231px' }}
                    />
                </Dialog>
            </Transition>
        </>
    )
}

export default TagsModal