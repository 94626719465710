import React,{useState, useEffect} from 'react'
import Header from '../../components/header/Header'
import HeroSection from '../../components/HomeComponents/herosection/HeroSection'
import RecentlyPlayed from '../../components/HomeComponents/recentlyplayed/RecentlyPlayed'
import Carousel from '../../components/HomeComponents/carousel/Carousel';
import CategorySection from '../../components/HomeComponents/categorysection/CategorySection';
import Popular from '../../components/HomeComponents/popularsection/Popular';
import LatestClasses from '../../components/HomeComponents/latestClasses/LatestClasses';
import DonationSection from '../../components/HomeComponents/donationsection/DonationSection';
import Footer from '../../components/footer/Footer';
import { Helmet } from 'react-helmet';

function Home() {
  
  const [isLogedin, setIsLogedin] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        setIsLogedin(true)
    }
    else {
        setIsLogedin(false)
    }
}, [])
  return (
   <div>
   <Header/>
   {
    isLogedin  &&
    <RecentlyPlayed/>

   }
   {/* {
    !isLogedin &&
    <HeroSection/>
   } */}
   <Carousel/>
   <CategorySection/>
   <Popular/>
   <LatestClasses/>
   <DonationSection/>
   <Footer/>
   </div>
  )
}

export default Home