import React from 'react'
import logo from "../../assets/logo-dark.svg"
import { Link, useNavigate } from "react-router-dom";
import SignupForm from './SignupForm';
import LoginForm from './LoginForm';
import ForgetForm from './ForgetForm';
function Background({ step, setStep }) {

    const currentPath = window.location.pathname;
    const navigate = useNavigate();
    return (
        <div>
            <div className='mx-auto flex flex-col md:flex-row max-w-7xl items-center justify-between p-6 lg:px-8'>
                <div className="flex lg:flex-1">
                    <Link
                        to="/"
                        className="-m-1.5 p-1.5"
                    >
                        <span className="sr-only">Inside Cassidus</span>
                        <img className="h-11 w-48" src={logo} alt="Inside Cassidus" />
                    </Link>
                </div>
                <div className='border-[1px] border-primaryDark rounded-3xl px-[32px] py-[14px] hidden md:inline-flex justify-center items-center h-[48px]'>

                    <Link
                        to="mailto:Info@insidechassidus.org"
                        className="text-[16px] font-normal leading-7 text-primaryDark"
                    >
                        Info@insidechassidus.org
                    </Link>

                </div>
            </div>


            <div className='mx-auto gap-y-3 flex flex-col md:flex-row max-w-7xl items-start md:items-center justify-between p-5 lg:px-8'>
                <div className='text-primaryDark text-lg md:text-3xl xl:text-5xl font-extrabold max-w-[900px] leading-normal'>
                    Providing resources for studying Chassidic philosophy from the inside
                </div>
                <div className='lg:px-8 space-y-3 md:space-y-0 w-[200px] xl:w-[unset]'>
                    <div className='text-xl xl:text-[28px] font-bold'>1000+</div>
                    <div className='flex items-center text-sm xl:text-xl gap-x-2'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                <path d="M18.813 13.297V1.11065C18.8122 0.944249 18.7716 0.780155 18.6941 0.630557C18.6167 0.480959 18.5044 0.349701 18.3657 0.246531C18.2289 0.142417 18.0691 0.0684699 17.8979 0.0301266C17.7268 -0.00821679 17.5487 -0.00998186 17.3767 0.024961L6.78111 2.24066C6.51954 2.29617 6.28595 2.43404 6.11896 2.63146C5.95198 2.82888 5.86162 3.07403 5.86282 3.32635V12.5658C5.05912 12.2088 4.15406 12.1066 3.28342 12.2746C2.41277 12.4426 1.62349 12.8717 1.03397 13.4976C0.444451 14.1235 0.0864864 14.9123 0.0137767 15.7459C-0.058933 16.5794 0.157532 17.4126 0.630699 18.1206C1.10387 18.8286 1.80822 19.3731 2.6381 19.6724C3.46798 19.9718 4.37863 20.0098 5.23345 19.7809C6.08826 19.552 6.84115 19.0684 7.37916 18.4027C7.91717 17.737 8.2113 16.9251 8.21741 16.0888C8.22959 15.8969 8.22959 15.7046 8.21741 15.5127V4.21263L16.4585 2.49547V10.3501C15.6543 9.99286 14.7487 9.89075 13.8776 10.0591C13.0065 10.2275 12.217 10.6573 11.6276 11.2839C11.0382 11.9105 10.6808 12.7001 10.609 13.5342C10.5372 14.3683 10.7549 15.2018 11.2294 15.9095C11.7039 16.6173 12.4096 17.161 13.2405 17.4591C14.0714 17.7573 14.9827 17.7937 15.8375 17.5629C16.6923 17.3321 17.4444 16.8466 17.9809 16.1793C18.5175 15.5119 18.8095 14.6988 18.813 13.862C18.8242 13.6738 18.8242 13.4852 18.813 13.297Z" fill="#161E2E" />
                            </svg>
                        </span>
                        Audio and
                    </div>
                    <div className='flex items-center text-sm xl:text-xl gap-x-2'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.33214 2.25813C1.375 3.39905 1.375 5.09416 1.375 8.48438V9.51562C1.375 12.9059 1.375 14.6009 2.33214 15.7419C2.50736 15.9507 2.70313 16.1422 2.91664 16.3136C4.08292 17.25 5.8157 17.25 9.28125 17.25C12.7468 17.25 14.4796 17.25 15.6459 16.3136C15.8594 16.1422 16.0551 15.9507 16.2303 15.7419C17.1875 14.6009 17.1875 12.9059 17.1875 9.51562V8.48438C17.1875 5.09416 17.1875 3.39905 16.2303 2.25813C16.0551 2.04926 15.8594 1.85775 15.6459 1.68633C14.4796 0.75 12.7468 0.75 9.28125 0.75C5.8157 0.75 4.08292 0.75 2.91664 1.68633C2.70313 1.85775 2.50736 2.04926 2.33214 2.25813ZM6.41672 10.6976V7.24086C6.41672 6.20383 7.33339 6.11064 7.92922 6.37665C8.5205 6.64065 8.84386 6.81709 9.26897 7.04904C9.56881 7.21265 9.91926 7.40387 10.4501 7.67292C10.4856 7.69095 10.5214 7.70899 10.5572 7.72705C11.2266 8.06475 11.9167 8.41287 11.9167 8.97768C11.9167 9.46906 11.4791 9.70532 10.862 10.0385C10.7319 10.1088 10.5938 10.1833 10.4501 10.2655C9.89992 10.5802 9.51282 10.772 8.94901 11.0513C8.66731 11.1909 8.34151 11.3523 7.92922 11.5618C6.97859 12.0449 6.41672 11.541 6.41672 10.6976Z" fill="#161E2E" />
                                <path d="M17.1875 6.55907L17.8212 6.19241C19.694 5.10868 20.6306 4.56681 21.3153 5.05655C22 5.54628 22 6.75793 22 9.18124V9.50627C22 11.9296 22 13.1412 21.3153 13.6309C20.6306 14.1207 19.694 13.5788 17.8212 12.4951L17.1875 12.1284V6.55907Z" fill="#161E2E" />
                            </svg>
                        </span>
                        Video lectures
                    </div>
                </div>
                <div className='border-[1px] border-primaryDark rounded-3xl px-[24px] py-[12px] inline-flex  md:hidden  justify-center items-center h-[48px]'>

                    <Link
                        to="mailto:Info@insidechassidus.org"
                        className="text-[14px] font-normal leading-7 text-primaryDark"
                    >
                        Info@insidechassidus.org
                    </Link>

                </div>
            </div>
            <div className='relative signup-bg gap-y-8 gap-x-2 mx-5 xl:mx-auto flex flex-col-reverse md:flex-row max-w-7xl items-center justify-end md:justify-between p-4 lg:px-8 min-h-[476px] rounded-[32px] border-2 border-white  bg-gradient-to-b from-[rgba(0,0,0,0.4)] to-[rgba(0,0,0,0.4)]'>
                <div className='absolute left-4 lg:left-8 top-4 text-whiteGrey  md:max-w-[50%]'>
                    {
                        currentPath === '/sign-up' &&
                        <>
                            <h2 className='leading-[normal] text-[22px] md:text-[40px] font-bold mb-2'>
                                Sign Up
                            </h2>
                            <p className='hidden md:block  text-sm xs:text-base md:text-lg font-normal'>
                                Welcome! Let’s create an account
                            </p>
                        </>

                    }
                    {
                        currentPath === '/sign-in' &&
                        <>
                            <h2 className='leading-[normal] text-[22px] md:text-[40px] font-bold mb-2'>
                                Sign In
                            </h2>
                            <p className='hidden md:block text-sm xs:text-base md:text-lg font-normal'>
                                Welcome back. Let’s start from where you left
                            </p>
                        </>

                    }
                    {
                        currentPath === '/forget-password' &&
                        <>
                            {
                                step === 0 &&
                                <>
                                    <h2 className='whitespace-nowrap leading-[normal] text-lg xs:text-[22px] md:text-[40px] font-bold mb-2'>
                                        Forgot your password?
                                    </h2>
                                    <p className='hidden md:block text-sm xs:text-base md:text-lg font-normal'>
                                        Enter the email address you used when you joined and we’ll send you instructions to reset your password.
                                    </p>
                                </>
                            }
                            {
                                step === 1 &&
                                <>
                                    <h2 className='whitespace-nowrap leading-[normal] text-lg xs:text-[22px] md:text-[40px] font-bold mb-2'>
                                        OTP verification
                                    </h2>
                                    <p className='hidden md:block text-sm xs:text-base md:text-lg font-normal'>
                                        Your password will be updated
                                    </p>
                                </>
                            }
                            {
                                step === 2 &&
                                <>
                                    <h2 className='whitespace-nowrap leading-[normal] text-lg xs:text-[22px] md:text-[40px] font-bold mb-2'>
                                        Reset your password
                                    </h2>
                                    <p className='hidden md:block text-sm xs:text-base md:text-lg font-normal'>
                                        Your password will be updated
                                    </p>
                                </>
                            }
                        </>

                    }
                </div>
                <div className='md:self-end w-full'>
                    {
                        currentPath === '/sign-up' &&
                        <div className='text-sm text-center pb-2 md:text-left md:pb-1 md:text-base font-normal text-[#D2D6DC]'>By Signing up, you agree to our
                            <Link to={'/privacy-policy'} className='font-bold pl-1 underline'>Privacy Policy & Terms</Link>
                        </div>
                    }
                    <div className='flex justify-between flex-col lg:flex-row items-start lg:items-center bg-whiteGrey mx-auto md:mx-0 max-w-[341px] lg:max-w-[unset] lg:w-[388px] h-[93px] lg:h-[84px] rounded-xl py-3 lg:py-0 px-4 md:px-8'>
                        <span className='text-sm font-semibold'>
                            {
                                currentPath === '/sign-up' &&
                                "Already have an account?"
                            }
                            {
                                (currentPath === '/sign-in' || currentPath === '/forget-password') &&
                                "Don’t have an account?"
                            }
                        </span>
                        {
                            currentPath === '/sign-up' &&
                            <button
                                type="button"
                                onClick={() => navigate('/sign-in')}
                                className="flex gap-x-1 h-10 lg:h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-8 py-1.5 text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            >
                                Sign In
                                <span aria-hidden="true" className='text-[13px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z" fill="#F4FBF7" />
                                    </svg>
                                </span>
                            </button>
                        }
                        {
                            (currentPath === '/sign-in' || currentPath === '/forget-password') &&
                            <button
                                type="button"
                                onClick={() => navigate('/sign-up')}
                                className="flex gap-x-1 lg:h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-8 py-1.5 text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            >
                                Sign Up
                                <span aria-hidden="true" className='text-[13px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z" fill="#F4FBF7" />
                                    </svg>
                                </span>
                            </button>
                        }
                    </div>
                </div>
                <div className={`mt-8 md:mt-0 md:self-end w-full md:w-[unset]`}>
                    <div className="flex md:bg-whiteGrey min-h-full flex-1 flex-col justify-center py-3 md:px-4 rounded-3xl mx-auto max-w-[337px]  md:w-[373px]">
                        {
                            currentPath === '/sign-up' &&
                            <SignupForm />
                        }
                        {
                            currentPath === '/sign-in' &&
                            <LoginForm />
                        }
                        {
                            currentPath === '/forget-password' &&
                            <ForgetForm
                                step={step}
                                setStep={setStep}
                            />
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Background