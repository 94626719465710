import React, { useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Banner from '../../components/banner/Banner'
import img from '../../assets/submit-question.png'
import { UserSubmitQuestion } from '../../service/service'
import Swal from 'sweetalert2'

function SubmitQuestion() {
    const [isLoading,setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        question: '',
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        UserSubmitQuestion(formData).then((result)=>{
            setIsLoading(false)
            if(result.status){
                Swal.fire({
                    icon: 'success',
                    title: result.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                setFormData({
                    name: '',
                    email: '',
                    question: '',
                })
            }
        })
        .catch((error)=>{
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: error,
                showConfirmButton: false,
                timer: 1500
            })
        })
    };
    return (
        <>
            <Header />
            <Banner title={'Submit a Question'} />
            <section id='contact-us' className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
                    <div className='flex flex-col items-center lg:flex-row gap-[25px]'>
                        <div className="lg:w-1/2 ">
                            <div className="h-[360px] md:h-[469px]" >
                                <figure className='h-full'>
                                    <img src={img} className='h-full rounded-3xl' alt="submit form" />
                                </figure>
                            </div>
                        </div>
                        <div className="lg:w-1/2">
                            <form onSubmit={handleSubmit} className="flex flex-col  justify-between gap-6">
                                <div className='text-base xs:text-lg font-semibold leading-[normal]'>
                                    Send a message directly to the Inside Chassidus Staff. Use our website contact form to send your questions or feedback.
                                </div>
                                <div className='flex flex-col sm:flex-row justify-between gap-6'>
                                    <div className='h-[85px] w-full rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                                        <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                            {`Full Name (optional)`}
                                        </label>
                                        <div className="">
                                            <input
                                                id="full-name"
                                                name="name"
                                                type="text"
                                                placeholder='Simon Adam'
                                                autoComplete="name"
                                                // required
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>
                                    <div className='h-[85px] w-full rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                                        <label htmlFor="email" className="block text-sm font-normal leading-5 text-primaryDark">
                                            {`Email address (optional)`}
                                        </label>
                                        <div className="">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder='simon123@gmail.com'
                                                autoComplete="email"
                                                // required
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <div className='h-[226px] w-full rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                                        <label htmlFor="comment" className="block text-sm font-normal leading-5 text-primaryDark">
                                        Question for Q&A<span className='text-red-700'>*</span>
                                        </label>
                                        <div className="h-[85%]">
                                            <textarea
                                                id="comment"
                                                name="question"
                                                type="text"
                                                placeholder='Type your comment or message here.'
                                                autoComplete="off"
                                                required
                                                value={formData.question}
                                                onChange={handleChange}
                                                className="block w-full h-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button disabled={isLoading} type="submit" className="bg-primaryBlue hover:bg-opacity-90 disabled:bg-opacity-50 flex w-full justify-center items-center text-whiteGrey text-base sm:text-lg md:text-base lg:text-lg font-bold leading-7 px-6 py-3 sm:px-8 sm:py-[14px] md:px-4 md:py-3 lg:px-8 lg:py-[14px] rounded-3xl h-[40px] sm:h-[48px]">
                                    Submit a Question
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default SubmitQuestion