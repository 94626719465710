import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import TwoFactorLand from "../adminPages/TwoFactorAuth/TwoFactorLand";

const PrivateRoute = ({ children, requiredPermissions }) => {
  const userPermissions = useSelector((state) => state.adminUser.userPermissions)
  
  const user = localStorage.getItem("adminToken");

  return user ? (userPermissions?.includes(requiredPermissions) ? (
  <TwoFactorLand>{children}  </TwoFactorLand>
  ) : (
    <Navigate to="/unauthorized" />
  )) : <Navigate to={"/admin"} />;
};

export default PrivateRoute;