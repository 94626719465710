import React from 'react'
import DashboardHeader from '../../adminComponents/DashboardComponents/DashboardHeader'
import DashboardSideNav from '../../adminComponents/DashboardComponents/DashboardSideNav'
import MenuSideNave from '../../adminComponents/MenuManagement/MenuSideNav'
import { useState } from 'react'
import MenuContainer from '../../adminComponents/MenuManagement/MenuContainer'
import { ListAdminMenu, SaveAdminMenu } from '../../service/service'
import { useEffect } from 'react'
import Swal from 'sweetalert2';
import { ThreeCircles } from 'react-loader-spinner'
import { useDispatch } from 'react-redux'
function MenuManagement() {
    const [isLoading, setIsLoading] = useState(false)
    const [searchCategory, setSearchCategory] = useState("");
    const [formData, setFormData] = useState({
        categories: [],
        series: []
    })
    // const [removeIds, setRemoveIds] = useState([])
    const [parentItem, setParentItem] = useState([]);
    const [categories, setCategories] = useState([]);
    const [data,setData]= useState([])
    function convertIdToString(id) {
        if (typeof id === 'number') {
            return id.toString();
        } else if (typeof id === 'string') {
            return id;
        } else {
            // Handle other data types or invalid input if needed
            return null; // Return null for non-integer or non-string values
        }
    }

    // function getAllIds(data) {
    //     const ids = [];

    //     for (const item of data) {
    //         const categoryId = typeof item.category_id === 'string' ? parseFloat(item.category_id) : item.category_id;

    //         ids.push(categoryId); // Add the ID of the current object

    //         if (item.sub_category && Array.isArray(item.sub_category)) {
    //             // If there are sub-categories, recursively get their IDs
    //             ids.push(...getAllIds(item.sub_category));
    //         }
    //     }

    //     return ids;
    // }
    function extractAllIds(data, result = []) {
        if (Array.isArray(data)) {
          data.forEach((item) => {
            if (item.id) {
              const idAsInt = parseInt(item.id, 10);
              if (!isNaN(idAsInt)) {
                result.push(idAsInt);
              }
            }
      
            if (Array.isArray(item.items) && item.items.length > 0) {
              extractAllIds(item.items, result);
            }
          });
        }
      
        return result;
      }
    function transformData(data) {
        return data.map((item) => {
            const { category_id, order, sub_category, name, title } = item;

            const transformedItem = {
                id: convertIdToString(category_id),
                name: name ?? title,
                order_id: order,
                items: Array.isArray(sub_category) ? transformData(sub_category) : [],

            };

            return transformedItem;
        });
    }

    const deleteMenuItem=(id)=>{
      
        const updatedData = [...categories];
    
        function recursiveDelete(arr) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
              arr.splice(i, 1);
              return true;
            } else if (arr[i].items && Array.isArray(arr[i].items)) {
              if (recursiveDelete(arr[i].items)) {
                return true;
              }
            }
          }
          return false;
        }
    
        if (recursiveDelete(updatedData)) {
          setCategories(updatedData);
        }

        const updatedParentData = updatedData.map((item) => {
            return {
                id: item.id.toString(),
                name: item.name,
                type: 'category',
                items: []

            }
        })
      
        setParentItem(updatedParentData)
    }
  
    useEffect(() => {
        setIsLoading(true)
       
        showMenuList()
    }, [])
    const [menuItemsIds, setMenuItemsIds] = useState([])
    useEffect(() => {
       const allIds = extractAllIds(categories);
       setMenuItemsIds(allIds)
    }, [categories])
const showMenuList =(msg)=>{
    ListAdminMenu().then((result) => {
        setIsLoading(false)
        if (result.status) {
          
            setData(result.data)
            const transformedData = transformData(result.data);
            // const allIds = getAllIds(result.data);
          
            // setRemoveIds(allIds)
            setCategories(transformedData)
            const updatedData = transformedData.map((item) => {
                return {
                    id: item.id.toString(),
                    name: item.name,
                    type: 'category',
                    items: []

                }
            })
          if(msg){
            Swal.fire({
                icon: 'success',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            })
          }
          else{

              setParentItem([...parentItem, ...updatedData])
          }
            
        }
    })
}

    function UpdateData(data) {
        return data.map((item, index) => {
            const { id, items, name, type } = item;

            const transformedItem = {
                id,
                name,
                order_id: index + 1,
                items: Array.isArray(items) ? UpdateData(items) : [],
                type
            };

            return transformedItem;
        });
    }



    const saveMenu = () => {
      
        setIsLoading(true)
        const cat = UpdateData(categories)
        SaveAdminMenu({ data: cat }).then((result) => {
           
            if (result.status) {
               
                showMenuList('Menu Updated Successfully')
                
                // dispatch(updateAdminUser({
                //     editorFormData: {
                //         ...formData,
                //         categories: []
                //     }
                // }));
            }
        })
            .catch((error) => {
                setIsLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: error.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
    return (
        <>
            {
                isLoading &&
                <div className="overlay">
                    <div className="loaderImage-container">
                        <ThreeCircles
                            height="100"
                            width="100"
                            color="#F9F9F9"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor=""
                            innerCircleColor=""
                            middleCircleColor=""
                        />
                    </div>
                </div>
            }
            <div className='bg-whiteGrey'>
                <DashboardSideNav />
                <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
                    <DashboardHeader title={'Menu'}
                        // headerButton={}
                        subtitle={'Menu Management'} />
                    <div className='pr-10 pl-[31px] pt-8 flex gap-x-10'>
                        <div className='min-w-[330px] max-w-[350px]'>
                            <div className='text-primaryDark text-xl font-bold mb-4'>
                                Add menu items
                            </div>
                        </div>
                        <div className='flex-grow max-w-[65%] space-y-6'>
                            <div className='text-primaryDark text-xl font-bold mb-4'>
                                Classes Menu structure
                            </div>
                        </div>

                    </div>
                    <div className='pr-10 pl-[31px] pb-8 flex min-h-screen gap-x-10 mb-20'>
                        <div className='min-w-[330px] max-w-[350px] bg-whiteGrey rounded-lg '>
                            <MenuSideNave
                                categories={categories}
                                setCategories={setCategories}
                                formData={formData}
                                menuItemsIds={menuItemsIds}
                                parentItem={parentItem}
                                setParentItem={setParentItem}
                                searchCategory={searchCategory} 
                                setSearchCategory={setSearchCategory}
                            
                            />
                        </div>
                        <div className='flex-grow max-w-[65%] bg-whiteGrey rounded-lg space-y-6 py-6 px-4'>
                            <MenuContainer
                                categories={categories}
                                setCategories={setCategories}
                                deleteMenuItem={deleteMenuItem}
                            />
                        </div>
                    </div>
                    <div className='bg-[#D2D6DC] w-[100%] fixed bottom-0 shadow-xl'>
                        <div className='flex justify-end px-16 py-5 sm:mr-[246px] lg:mr-[310px]'>
                            <button
                            //  disabled={categories.length <= 0}
                              onClick={saveMenu} className='bg-primaryBlue hover:opacity-90 text-whiteGrey font-semibold px-4 py-2 rounded-md disabled:opacity-50'>
                                Save Menu
                            </button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default MenuManagement