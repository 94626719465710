import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react';
import { LogoutUser } from '../../service/service';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdminUser } from '../../store/slices/AdminUserSlice';
function DashboardHeader({ title, subtitle, headerButton,Headerinput }) {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.adminUser.userData)

    const navigate = useNavigate();
    function SignOutIcon(props) {
        return (

            <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
            </svg>

        )
    }
    const handleSignOut = () => {
        const token = localStorage.getItem('adminToken');
        LogoutUser(token).then((result) => {
            // console.log(result)
            localStorage.removeItem('adminToken');
            localStorage.removeItem('adminData');
            navigate("/admin");
            dispatch(logoutAdminUser())
        })
            .catch((error) => {
                console.log(error.message);

            });

    };
    return (
        <div className='px-[22px] sm:px-8 bg-whiteGrey'>
            <div className='flex justify-between items-center text-primaryDark sm:h-[72px] text-left'>
                <div className='flex justify-center items-center gap-3 text-2xl leading-[21px] font-semibold'>
                    <div className='hidden sm:block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M4 6.5H14.5M4 12.5H20M4 18.5H14.5" stroke="#161E2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <span>
                        {title}
                    </span>

                </div>
                <div className='flex justify-between items-center gap-x-5'>
                  {Headerinput}
                    <div className="relative flex">
                        <Menu>
                            {({ open }) => (
                                <>
                                    <Menu.Button >
                                        <div className='flex gap-x-3 items-center justify-center'>
                                            <div className='flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>

                                            </div>
                                            <div className='text-base font-semibold leading-[21px] text-primaryDark'>
                                                {userData?.name}
                                            </div>
                                            <div className='flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M10.25 15.6365C10.1163 15.6365 9.98251 15.5854 9.88045 15.4833L4.65319 10.2561C4.44894 10.0518 4.44894 9.72109 4.65319 9.51696C4.85745 9.31284 5.1882 9.31271 5.39232 9.51696L10.25 14.3746L15.1077 9.51696C15.312 9.31271 15.6427 9.31271 15.8468 9.51696C16.051 9.72122 16.0511 10.052 15.8468 10.2561L10.6196 15.4833C10.5175 15.5854 10.3837 15.6365 10.25 15.6365Z" fill="#161E2E" />
                                                </svg>
                                            </div>
                                        </div>
                                    </Menu.Button>

                                    {open && (
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                static
                                                className="z-10 bg-white border w-56 border-gray-300 absolute right-0 top-8 mt-2 rounded-md shadow-lg focus:outline-none"
                                            >
                                                <div className="px-1 py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className={`${active ? 'bg-primaryDark text-whiteGrey' : 'text-primaryDark'
                                                                    } group flex w-full items-center rounded-md px-2 py-2 text-base font-semibold`}
                                                                onClick={handleSignOut}
                                                            >
                                                                {active ? (
                                                                    <SignOutIcon
                                                                        className="mr-2 h-5 w-5 text-whiteGrey"
                                                                        aria-hidden="true"
                                                                    />
                                                                ) : (
                                                                    <SignOutIcon
                                                                        className="mr-2 h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                                Log Out
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    )}
                                </>
                            )}
                        </Menu>
                    </div>
                </div>
            </div>
            <hr className='bg-[#D2D6DC] h-[1px]' />
            <div className='flex justify-between items-center  text-primaryDark h-[76px] text-left sm:border-b-[1px]'>
                <div className='flex justify-center items-center gap-3 text-lg lg:text-[22px] leading-[21px] font-semibold'>
                    <span className='opacity-80'>
                        {title}
                    </span>
                    /
                    <span>
                        {subtitle}
                    </span>
                </div>
                {headerButton}
            </div>
        </div>
    )
}

export default DashboardHeader