import React from 'react'

function DonationPageMobile() {
  return (
   <div>
       <div className="keela-embed-form" data-src="https://give-usa.keela.co/embed/XfwzpxcvtX9Wvg3JX">
            <div className="keela-loading">
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </div>
        </div>
   </div>
  )
}

export default DonationPageMobile