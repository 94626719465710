import React, { useState, useRef, useEffect } from 'react';
import { ForwordTen, PauseIcon, PlayIcon, RewindTen, VolumeIcon, VolumeMuteIcon } from '../assets/icon-file';

const CustomAudioPlayer = ({ audioUrl, onPlay }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [speed, setSpeed] = useState(1.0);
    const [volume, setVolume] = useState(1.0);
    const [isMuted, setIsMuted] = useState(false);
    const [dragging, setDragging] = useState(false);
    useEffect(() => {
        const audioElement = audioRef.current;

        const handleLoadedMetadata = () => {
            setDuration(audioElement.duration);
        };

        // const handleTimeUpdate = () => {
        //     setCurrentTime(audioElement.currentTime);
        // };
        const handleTimeUpdate = () => {
            if (!dragging) {
                setCurrentTime(audioElement.currentTime);
            }
        };
        const handleEnded = () => {
            // Track completed, pause and reset to start
            audioElement.pause();
            audioElement.currentTime = 0;
            setIsPlaying(false);
        };

        audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
        audioElement.addEventListener('timeupdate', handleTimeUpdate);
        audioElement.addEventListener('ended', handleEnded);

        return () => {
            audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
            audioElement.removeEventListener('ended', handleEnded);
        };
    }, [dragging]);

    const playPauseToggle = () => {
        if (audioRef.current.paused) {
            audioRef.current.play();
            setIsPlaying(true);
            if (onPlay) {
                onPlay(); // Call the onPlay function if provided
            }
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handlePlaybackSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        audioRef.current.playbackRate = newSpeed;
    };

    const handleVolumeChange = (newVolume) => {
        setVolume(newVolume);
        audioRef.current.volume = newVolume;
    };

    // const handleTimeChange = (newTime) => {
    //     audioRef.current.currentTime = newTime;
    // };
    const handleTimeChange = (newTime) => {
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const handleDragStart = () => {
        setDragging(true);
    };

    const handleDragEnd = (e) => {
        setDragging(false);
        handleTimeChange(parseFloat(e.target.value));
    };

    const toggleMute = () => {
        const newIsMuted = !isMuted;
        setIsMuted(newIsMuted);
        audioRef.current.muted = newIsMuted;
    };
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    return (
        <>
            <audio className='hidden' ref={audioRef} src={audioUrl} />

            <div className='flex items-center bg-primaryDark text-whiteGrey p-4 rounded-xl gap-7 flex-col sm:flex-row'>

                <button onClick={playPauseToggle}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</button>

                <div className='flex flex-col flex-1 relative bottom-1 w-full'>
                    <input
                        type="range"
                        className='w-full cursor-pointer h-1'
                        min="0"
                        max={duration}
                        step="1"
                        onChange={(e) => setCurrentTime(parseFloat(e.target.value))}
                        onMouseUp={(e) => handleDragEnd(e)}
                        onTouchEnd={(e) => handleDragEnd(e)}
                        onMouseDown={handleDragStart}
                        onTouchStart={handleDragStart}
                        value={currentTime}
                    />
                    <div className='flex justify-between absolute w-full top-3 text-xs'>
                        <span>{formatTime(currentTime)}</span> <span>{formatTime(duration)}</span>
                    </div>
                </div>
                <div className='flex items-center gap-5'>
                    <div>
                        {/* <span>Playback Speed:</span> */}
                        <select className='bg-primaryDark' value={speed} onChange={(e) => handlePlaybackSpeedChange(parseFloat(e.target.value))}>
                            <option value={0.5}>0.5x</option>
                            <option value={1.0}>1.0x</option>
                            <option value={1.5}>1.5x</option>
                            <option value={2.0}>2.0x</option>
                        </select>
                    </div>
                    <div className='flex items-center gap-5'>
                        <button onClick={() => handleTimeChange(currentTime - 10)}>
                            <RewindTen />
                        </button>
                        <button onClick={() => handleTimeChange(currentTime + 10)}>
                            <ForwordTen />
                        </button>
                    </div>


                    <div className='flex gap-1 items-center'>
                        <span onClick={toggleMute}>{isMuted ? <VolumeMuteIcon /> : <VolumeIcon />}</span>
                        <input
                            type="range"
                            className='h-1 w-16'
                            min="0"
                            max="1"
                            step="0.1"
                            value={volume}
                            onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};


export default CustomAudioPlayer;
