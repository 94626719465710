import React, { useEffect, useState } from 'react';
import './App.css'
import {Route, Routes, useLocation } from "react-router-dom";
import Home from './pages/homePage/Home';
import Signup from './pages/signupLogin/Signup';
import Signin from './pages/signupLogin/Signin';
import ContactUs from './pages/contactusPage/ContactUs';
import AboutUs from './pages/aboutusPage/AboutUs';
import LivePage from './pages/livePage/LivePage';
import Login from './adminPages/LoginPage/Login';
import ResetPassword from './adminPages/RestetPassword/ResetPassword';
import PrivateRoute from './route/PrivateRoute';
import UserManagement from './adminPages/Dashboard/UserManagement';
import DashboardRoute from './route/DashboardRoute';
import RoleManagement from './adminPages/Dashboard/RoleManagement';
import Unauthorized from './adminPages/unauth/Unuth';
import NotFound from './pages/notfoundPage/NotFound';
import ClassManagement from './adminPages/Dashboard/ClassManagement';
import PermissionManagement from './adminPages/Dashboard/PermissionManagement';
import CategoriesManagement from './adminPages/Dashboard/CategoriesManagement';
import TagsManagement from './adminPages/Dashboard/TagsManagement';
import SeriesManagement from './adminPages/Dashboard/SeriesManagement';
import ContentManagement from './adminPages/Dashboard/ContentManagement';
import DonationPage from './pages/donationPage/DonationPage';
import MediaLibrary from './adminPages/Dashboard/MediaLibrary';
// import DonationScreen from './pages/mobileDonation/DonationScreen';
import DonationPageMobile from './pages/donationPage/DonationPageMobile';
import QuestionManagement from './adminPages/Dashboard/QuestionManagement';
import ChaiClub from './pages/chaiClubPage/ChaiClub';
import SubmitQuestion from './pages/submitQuestion/SubmitQuestion';
import ViewAll from './pages/searchResult/ViewAll';
import SearchClasses from './pages/searchResult/SearchClasses';
import MenuManagement from './adminPages/Dashboard/MenuManagement';
import SearchSeries from './pages/searchResult/SearchSeries';
import TeamManagement from './adminPages/Dashboard/TeamManagement';
// import UserCategory from './pages/userCategoyPages/UserCategory';
import ContactUsManagement from './adminPages/Dashboard/ContactUsManagement';
import AdminSetting from './adminPages/Dashboard/AdminSetting';
import DailyTanya from './components/HomeComponents/categorysection/DailyTanya';
import MonthlyClasses from './components/HomeComponents/categorysection/MonthlyClasses';
import AllLatestClasses from './components/HomeComponents/latestClasses/AllLatestClasses';
import Bookmark from './pages/bookmarkPage/Bookmark';
import LoginRoute from './route/LoginRoute';
import ForgetPassword from './pages/signupLogin/ForgetPassword';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import TagsTypeManagement from './adminPages/Dashboard/TagsTypeManagement';
// import FunctionalApp from './ReuseableComponents/testfile';
import PageBuilderManagement from './adminPages/Dashboard/PageBuilderManagement';
import SearchPage from './components/header/SearchPage';
import UserPrivateRoute from './route/UserPrivateRoute';
import ProfilePage from './pages/profilePage/ProfilePage';
// import WeeklyParsha from './components/HomeComponents/categorysection/WeeklyParsha';
import ParshaOfTheWeek from './components/HomeComponents/categorysection/WeeklyParsha';
import { Helmet } from 'react-helmet';
import { routeDescription, routeTitles } from './metadata/MetaData';
import NonSpecifiedRoute from './pages/notfoundPage/NonSpecifiedRoute';
import SearchTags from './pages/searchResult/SearchTags';

// import MQTTComponent from './MQTTComponent';

function App() {
const [MetaData, setMetaData]= useState({
  title:'',
  description:"",
  url:"window.location.origin"
})
 
  
const location = useLocation();
  useEffect(() => {
    const currentRoute = location.pathname;
    const url = window.location.origin+currentRoute;
    const title = routeTitles[currentRoute] || "Inside Chassidus | Audio Classes | Chassidus for Everyone";
    const description = routeDescription[currentRoute] || "Welcome to Inside Chassidus where you can learn at your own pace with world renowned teacher and inspirational speaker Rabbi Yossi Paltiel.";
   
    setMetaData((prevData) => ({
      ...prevData,
      title,
      description,
      url
  }));
    
  }, [location]);

  return (

    <div className="App">
       <Helmet>
       <title>{`${MetaData.title}`}</title>
       <meta name="description" content={MetaData.description} />
       <meta property="og:locale" content="en_US" />
       <meta property="og:type" content="website/article"></meta>
       <meta property="og:title" content={MetaData.title}></meta>
       <meta property="og:description" content={MetaData.description}></meta>
       <meta property="og:url" content={MetaData.url}></meta>
       <meta property="og:site_name" content="Inside Chassidus" />

       <meta
            property="og:image"
            itemprop="image"
            content="https://media.insidechassidus.org/wp-content/uploads/20201027210730/r-paltiel-inside-chassidus-new.png"
          />
          <meta property="og:image:type" content="image/jpeg/png" />

          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="200" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={MetaData.title} />
          <meta property="twitter:description" content={MetaData.description} />
          <meta
            property="twitter:image"
            itemprop="image"
            content="https://media.insidechassidus.org/wp-content/uploads/20201027210730/r-paltiel-inside-chassidus-new.png"
          />
          <meta property="twitter:image:width" content="300" />
          <meta property="twitter:image:height" content="200" />
      </Helmet>
       {/* <MQTTComponent/> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        
        <Route exact path='/sign-in' element={
          <LoginRoute>
           <Signin />
          </LoginRoute>
        } />
        <Route exact path='/sign-up' element={
          <LoginRoute>
           <Signup /> 
          </LoginRoute>
        } />
        <Route exact path='/forget-password' element={
          <LoginRoute>
           <ForgetPassword />
          </LoginRoute>
        } />

        {/* <Route exact path="/sign-up" element={<Signup />} /> */}
        {/* <Route exact path="/sign-in" element={<Signin />} /> */}
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/ask-question" element={<SubmitQuestion />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/live" element={<LivePage />} />
        <Route exact path="/donations" element={<DonationPage/>} />
        <Route exact path="/chai-club" element={<ChaiClub/>} />
        <Route exact path="/donations-mbl" element={<DonationPageMobile/>} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route exact path="/view-all" element={<ViewAll />} />
        <Route exact path="/daily-tanya" element={<DailyTanya/>} />
        <Route exact path="/daily-hayom-yom" element={<DailyTanya/>} />
        <Route exact path="/weekly-parsha-classes" element={<ParshaOfTheWeek/>} />
        <Route exact path="/monthly-classes" element={<MonthlyClasses/>} />
        <Route exact path="/latest-classes" element={<AllLatestClasses/>} />
        <Route exact path="/user-bookmarks" element={<UserPrivateRoute><Bookmark/></UserPrivateRoute>} />
        <Route exact path="/profile" element={<UserPrivateRoute><ProfilePage/></UserPrivateRoute>} />
        {/* <Route exact path="/:uniqueName" element={<UserCategory/>} /> */}
        {/* <Route exact path="/:uniqueName" element={<SearchClasses />} /> */}
        <Route exact path="/series/:uniqueName" element={<SearchSeries />} />
        <Route exact path="/tag/:uniqueName" element={<SearchTags/>} />
        <Route exact path="/unauthorized" element={<Unauthorized />} />
        <Route exact path="/search" element={<SearchPage/>} />
        <Route exact path='/admin/reset-password' element={<ResetPassword />} />
        <Route exact path='/admin' element={
          <DashboardRoute>
            <Login />
          </DashboardRoute>
        } />

        <Route
          exact
          path="/user-management"
          element={
            <PrivateRoute requiredPermissions={'view_user'}>
              <UserManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/role-management"
          element={
            <PrivateRoute requiredPermissions={'view_role'}>
              <RoleManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/permission-management"
          element={
            <PrivateRoute requiredPermissions={'view_permission'}>
              <PermissionManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/class-management"
          element={
            <PrivateRoute requiredPermissions={'view_class'}>
              <ClassManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/class-management/categories"
          element={
            <PrivateRoute requiredPermissions={'view_category'}>
              <CategoriesManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/class-management/tags"
          element={
            <PrivateRoute requiredPermissions={'view_tag'}>
              <TagsManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/class-management/tags-type"
          element={
            <PrivateRoute requiredPermissions={'view_tag'}>
              <TagsTypeManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/class-management/series"
          element={
            <PrivateRoute requiredPermissions={'view_series'}>
              <SeriesManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/content-management"
          element={
            <PrivateRoute requiredPermissions={'view_slider'}>
              <ContentManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/content-management/media-library"
          element={
            <PrivateRoute requiredPermissions={'view_gallery'}>
              <MediaLibrary/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/content-management/questions"
          element={
            <PrivateRoute requiredPermissions={'view_question'}>
              <QuestionManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/content-management/contact-us"
          element={
            <PrivateRoute requiredPermissions={'view_comment'}>
              <ContactUsManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/content-management/team"
          element={
            <PrivateRoute requiredPermissions={'view_team'}>
              <TeamManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/menu-management"
          element={
            <PrivateRoute requiredPermissions={'view_menu'}>
              <MenuManagement/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/profile-setting"
          element={
            <PrivateRoute requiredPermissions={'view_menu'}>
              <AdminSetting/>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/page-builder"
          element={
            <PrivateRoute requiredPermissions={'view_menu'}>
              <PageBuilderManagement/>
            </PrivateRoute>
          }
        />
         {/* <Route exact path="/test" element={<FunctionalApp/>} /> */}
        <Route path="*" element={<NonSpecifiedRoute />} />
      </Routes>

    </div>


  );

}

export default App;
