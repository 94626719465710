import React from 'react';
import Header from '../../components/header/Header'
// import { Link } from 'react-router-dom'
import Footer from '../../components/footer/Footer'

function ChaiClub() {

    return (
        <>
            <Header />
            <section id='donations-section' className='px-2 xs:px-5 pt-6 pb-[32px] md:pt-8'>
                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
                    <h1 className='text-primaryDark leading-[normal] text-[22px] md:text-[40px] font-extrabold mb-2 md:mb-4 '>
                        Join our Chai Club!
                    </h1>
                    <div className='text-primaryDark text-lg font-semibold'>
                        <p>
                             <a className='text-primaryBlue' href='mailto:info@insidechassidus.org'>Email us</a>, if you’d like to join!
                        </p>
                    </div>


                </div>
            </section>
            <section id='donation-form-container' className='px-2 xs:px-5 pt-6 pb-[32px] md:pt-8 chai-club'>
                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
                   <div className='mt-[100px] bg-white rounded-3xl max-w-[546px] h-[300px]'>
                    <div className='leading-[normal] text-[22px] md:text-[40px] font-extrabold p-6'>
                    Give Monthly Donation
                    </div>
                   <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" className='p-6 pt-0'>
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="NJEJTL53VWCWU" />
                        <table className='bg-[#D2D6DC] opacity-40 w-full h-[85px] rounded-xl mb-6'>
                            {/* <tr>
                                <td>
                                    <input type="hidden" name="on0" value="undefined" />
                                    undefined
                                </td>
                            </tr> */}
                            <tr>
                                <td>
                                    <select name="os0">
                                        <option value="Option 1">
                                            Option 1 $5.00 USD - month(s)
                                        </option>
                                        <option value="Option 2">
                                            Option 2 $10.00 USD - month(s)
                                        </option>
                                        <option value="Option 3">
                                            Option 3 $18.00 USD - month(s)
                                        </option>
                                        <option value="Option 4">
                                            Option 4 $36.00 USD - month(s)
                                        </option>
                                        <option value="Option 5">
                                            Option 5 $72.00 USD - month(s)
                                        </option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <input type="hidden" name="currency_code" value="USD" />
                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Subscribe" />
                    </form>
                   </div>
                </div>

            </section>
            <Footer />
        </>
    )
}

export default ChaiClub