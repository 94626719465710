import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { ResetPassword } from '../../service/service';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';

function CreateNewPassword({ emailData }) {

    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    const [formErrors, setFormErrors] = useState({
        password: '',
        confirmPassword: ''
    });

    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false
    });

    const handleTogglePassword = (inputName) => {
        setShowPassword((prevShowPassword) => ({
            ...prevShowPassword,
            [inputName]: !prevShowPassword[inputName]
        }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormErrors({
            password: '',
            confirmPassword: ''
        });

        if (validateForm()) {
            setloading(true)
            try {
                setloading(true)
                const data = {

                    password: formData.password,
                    confirm_password: formData.confirmPassword,
                    is_admin: true,
                    id: emailData?.id
                };
                const result = await ResetPassword(data);
                if (result.status) {
                    // localStorage.setItem('accessToken', result.token);
                    navigate('/admin')
                    setloading(false)
                    setFormData({
                        password: '',
                        confirmPassword: ''
                    });
                }
                else {
                    setloading(false)
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }
            } catch (error) {

                console.log(error.message);
                setloading(false)
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            }
        }
    };
    function validatePassword(password) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

        if (!regex.test(password)) {
            return false;
        }

        return true;
    }
    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!formData.password) {
            isValid = false;
            errors.password = 'Password is required';
        } else if (!(validatePassword(formData.password))) {
            isValid = false;
            errors.password = 'Invalid password';
        }

        if (!formData.confirmPassword) {
            isValid = false;
            errors.confirmPassword = 'Confirm Password is required';
        } else if (formData.confirmPassword !== formData.password) {
            isValid = false;
            errors.confirmPassword = 'Passwords do not match';
        }

        setFormErrors(errors);
        return isValid;
    };
    return (
        <div className='w-full'>
            <div className='max-w-[611px] mx-auto px-2 xs:px-4 sm:px-14 space-y-6'>
                <div className='text-2xl font-bold leading-[28px] text-center'>
                    Create New Password
                </div>
                <form className="space-y-[14px]" onSubmit={handleSubmit}>
                    {/* Email input */}
                    <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>

                        <div className='flex gap-x-1 items-center'>
                            <label htmlFor="password" className="block text-sm font-normal leading-5 text-primaryDark">
                                New Password
                            </label>
                            <InformationCircleIcon
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Password must be 8 characters or more, with at least one uppercase letter, one number, and one special character"
                                className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                aria-hidden="true"
                            />
                        </div>
                        <div className="relative">
                            <input
                                id="password"
                                name="password"
                                type={showPassword.password ? 'text' : 'password'}
                                autoComplete="new-password"
                                required
                                value={formData.password}
                                onChange={handleChange}
                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                            />
                            <span
                                className='absolute top-2 right-0 cursor-pointer'
                                onClick={() => handleTogglePassword('password')}
                            >
                                {
                                    showPassword.password ?
                                        (
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        ) :
                                        (
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                            </svg>


                                        )

                                }


                            </span>
                        </div>
                    </div>
                    {formErrors.password && <div className="text-red-500 px-3">{formErrors.password}</div>}
                    <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                        <label htmlFor="confirm-password" className="block text-sm font-normal leading-5 text-primaryDark">
                            Confirm Password
                        </label>
                        <div className="relative">
                            <input
                                id="confirm-password"
                                name="confirmPassword"
                                type={showPassword.confirmPassword ? 'text' : 'password'}
                                autoComplete="new-password"
                                required
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                            />
                            <span
                                className='absolute top-2 right-0 cursor-pointer'
                                onClick={() => handleTogglePassword('confirmPassword')}
                            >
                                {
                                    showPassword.confirmPassword ?
                                        (
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        ) :
                                        (
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                            </svg>


                                        )

                                }


                            </span>
                        </div>
                    </div>
                    {formErrors.confirmPassword && <div className="text-red-500 px-3">{formErrors.confirmPassword}</div>}

                    {/* Submit button */}
                    <div className='pt-[10px]'>
                        <button
                            type="submit"
                            disabled={loading}
                            className="flex w-full h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                        >
                            Reset Password
                        </button>
                    </div>
                    <Tooltip
                        id="my-tooltip"
                        place="bottom-start"
                        style={{ backgroundColor: "#161E2E", color: "#F9F9F9", width: '231px' }}
                    />
                </form>

            </div>
        </div>
    )
}

export default CreateNewPassword