import React, { useEffect, useState } from 'react';
import { LoginUser, ThirdPartyLogin } from '../../service/service';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserdata } from '../../store/slices/UserDataSlice';
import { AppleIcon, GoogleIcon } from '../../assets/icon-file';
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login'
import axios from 'axios';
function LoginForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setloading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.email) {
            newErrors.email = 'Email is required.';
        }

        if (!formData.password) {
            newErrors.password = 'Password is required.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateForm()) {
            setloading(true)
            LoginUser(formData)
                .then((result) => {
                    // console.log(result);
                    if (result.status) {
                        localStorage.setItem('accessToken', result.token);
                        localStorage.setItem('loginData', JSON.stringify(result.data));
                        setFormData({
                            email: '',
                            password: '',
                        });
                        setErrorMsg('')
                        navigate('/', { replace: true });

                        setloading(false)
                        dispatch(setUserdata({ userdata: result.data }));
                    }
                    else {
                        setErrorMsg(result.message)
                        setloading(false)
                    }

                })
                .catch((error) => {
                    console.log(error.message);
                    setErrorMsg(error.message)
                    setloading(false)
                });
        }
    };
    const [user, setUser] = useState(null);

    const newFu = res => {
        setUser(res);
    };
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleGoogle = useGoogleLogin({

        onSuccess: codeResponse => {
            // console.log(codeResponse, 'codeResponse');
            newFu(codeResponse);
        },
        onError: error => console.log('Login Failed:', error),
    });
    useEffect(() => {

        // console.log(user);
        if (user) {
            setloading(true)
            axios
                .get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json',
                        },
                    }
                )
                .then(res => {
                    // setProfile(res.data);
                    console.log(res.data)
                    ThirdPartyLogin({ ...res.data, type: 'google' }).then((result) => {
                        if (result.status) {
                            localStorage.setItem('accessToken', result.token);
                            localStorage.setItem('loginData', JSON.stringify(result.data));
                            setFormData({
                                email: '',
                                password: '',
                            });
                            setErrorMsg('')
                            navigate('/', { replace: true });

                            setloading(false)
                            dispatch(setUserdata({ userdata: result.data }));
                        }
                        else {
                            setErrorMsg(result.message)
                            setloading(false)
                        }
                    })
                        .catch((err) => {
                            setloading(false)
                            console.log(err.message)
                            setErrorMsg(err.message)
                        });

                })
                .catch(err => console.log(err));
        }
    }, [user]);

    const appleResponse = (response) => {
        // Handle the Apple response (optional)
        console.log('Apple Response:', response);
        
      };
    const handleAppleLoginFailure = error => {
        console.log("🚀  file: Login.js:200  handleAppleLoginFailure ~ error:", error)
        alert("fail")
        // Handle the error from Apple Login
        console.error(error);
    };
    const handleAppleLogin=(response)=>{
        console.log('handleAppleLogin:', response);
    }
    return (
        <form className="space-y-2" onSubmit={handleSubmit}>
            {/* Email input */}
            <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                <label htmlFor="email" className="block text-sm font-normal leading-5 text-primaryDark">
                    Email address
                </label>
                <div className="">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        placeholder='johndoe@mail.com'
                        value={formData.email}
                        onChange={handleChange}
                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                    />
                </div>
            </div>
            {errors.email && <p className="text-red-500 px-3">{errors.email}</p>}

            {/* Password input */}
            <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                <label htmlFor="password" className="block text-sm font-normal leading-5 text-primaryDark">
                    Password
                </label>
                <div className="relative">
                    <input
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        required
                        placeholder='******'
                        value={formData.password}
                        onChange={handleChange}
                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                    />
                    <span
                        className='absolute top-2 right-0 cursor-pointer'
                        onClick={handleTogglePassword}
                    >
                        {
                            showPassword ?
                                (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>

                                ) :
                                (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                )

                        }


                    </span>
                </div>
            </div>
            {errors.password && <p className="text-red-500 px-3">{errors.password}</p>}
            {errorMsg !== '' && <p className="text-red-500 px-3">{errorMsg}</p>}
            <div className='text-right underline'>
                <Link
                    className='text-sm font-normal leading-5 text-whiteGrey md:text-primaryDark'
                    to={'/forget-password'}
                >
                    Forgot password?
                </Link>
            </div>
            {/* Submit button */}
            <div>
                <button
                    type="submit"
                    disabled={loading}
                    className="flex w-full h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                >
                    Sign In
                </button>
            </div>
            <div className='flex'>
                <svg width="327" height="21" viewBox="0 0 327 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M160.502 15.098C159.597 15.098 158.771 14.888 158.024 14.468C157.277 14.0387 156.685 13.446 156.246 12.69C155.817 11.9247 155.602 11.066 155.602 10.114C155.602 9.162 155.817 8.308 156.246 7.552C156.685 6.78667 157.277 6.194 158.024 5.774C158.771 5.34467 159.597 5.13 160.502 5.13C161.417 5.13 162.247 5.34467 162.994 5.774C163.741 6.194 164.329 6.782 164.758 7.538C165.187 8.294 165.402 9.15267 165.402 10.114C165.402 11.0753 165.187 11.934 164.758 12.69C164.329 13.446 163.741 14.0387 162.994 14.468C162.247 14.888 161.417 15.098 160.502 15.098ZM160.502 13.992C161.183 13.992 161.795 13.8333 162.336 13.516C162.887 13.1987 163.316 12.746 163.624 12.158C163.941 11.57 164.1 10.8887 164.1 10.114C164.1 9.33 163.941 8.64867 163.624 8.07C163.316 7.482 162.891 7.02933 162.35 6.712C161.809 6.39467 161.193 6.236 160.502 6.236C159.811 6.236 159.195 6.39467 158.654 6.712C158.113 7.02933 157.683 7.482 157.366 8.07C157.058 8.64867 156.904 9.33 156.904 10.114C156.904 10.8887 157.058 11.57 157.366 12.158C157.683 12.746 158.113 13.1987 158.654 13.516C159.205 13.8333 159.821 13.992 160.502 13.992ZM168.358 8.574C168.582 8.13533 168.899 7.79467 169.31 7.552C169.73 7.30933 170.239 7.188 170.836 7.188V8.504H170.5C169.072 8.504 168.358 9.27867 168.358 10.828V15H167.084V7.328H168.358V8.574Z" fill="#515F7B" />
                    <line opacity="0.5" x1="-2.18557e-08" y1="10.75" x2="139" y2="10.75" stroke="#515F7B" strokeWidth="0.5" />
                    <line opacity="0.5" x1="188" y1="10.75" x2="327" y2="10.75" stroke="#515F7B" strokeWidth="0.5" />
                </svg>
            </div>
            <div className='flex gap-2'>
                <button
                    type="button"
                    disabled={loading}
                    onClick={handleGoogle}
                    className="flex w-full h-12 items-center justify-center rounded-3xl bg-transparent border-[1px] border-[#515F7B] hover:bg-opacity-[0.9]  px-3 py-1.5 text-base font-normal leading-7 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                >
                    <GoogleIcon />
                </button>

              
                <AppleLogin
                    clientId="com.senarios.inside.chassidus.app"
                    // clientId="com.senarios.inside"
                    redirectURI="https://developmentinside.com/"
                    responseType="code"
                    teamId="25W6BV4CQV"
                    // teamId="W6N67XY9Z4"
                    usePopup={true}
                    cssClass="custom_class "
                    scopes={"name email"}
                    state="origin:web"
                    responseMode="form_post"
                    callback={appleResponse}
                    render={({ onClick }) => (
                        <button
                        type="button"
                        // disabled={loading}
                         onClick={onClick}
                        className="flex w-full h-12 items-center justify-center rounded-3xl bg-transparent border-[1px] border-[#515F7B] hover:bg-opacity-[0.9]  px-3 py-1.5 text-base font-normal leading-7 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                    >
                        <AppleIcon />
                    </button>
                    )}
                    onSuccess={handleAppleLogin}
                    onFailure={handleAppleLoginFailure}
                />
            </div>
        </form>
    );
}

export default LoginForm;
