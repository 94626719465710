import { Menu } from '@headlessui/react';
import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DropdownIcon, XIcon } from '../assets/icon-file';
let searchTimeout;
const PaginateMultiSelect = forwardRef(({ setFilterData, fetchData, searchAPI, placeholder, selectedKey, selctedItems, selectMostUsed, setSelectMostUsed ,tag_type}, ref) => {

    const itemsPerPage = 100;

    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedItems, setSearchedItems] = useState([]);
    const searchInputRef = useRef(null);
    const [selected, setSelected] = useState([]);
    const [loading, setloading] = useState(false)
    const handleCheckbox = (event, itemId, itemObject) => {
        const { checked } = event.target;

        const updatedItems = items.map(item =>
            item.id === itemId ? { ...item, isShow: checked } : item
        );

        setItems(updatedItems);
        setSearchedItems(updatedItems);

        if (checked) {
            setSelected(prevSelected => [...prevSelected, itemObject]);
        } else {
            setSelected(prevSelected =>
                prevSelected.filter(selectedItem => selectedItem.id !== itemId)
            );
            if(selectMostUsed){
                // selectMostUsed
                setSelectMostUsed(prevSelected =>
                    prevSelected.filter(selectedItem => selectedItem.id !== itemId)
                );
            }
        }
    };

    useEffect(() => {
        setFilterData(prev => ({ ...prev, [selectedKey]: selected }));
        
        if (selected.length > 0) {
            setItems(prevItems => prevItems.map((obj) => {

                if (selected.some(x => x.id === obj.id))
                    obj.isShow = true

                return obj

            }));

        }
    }, [selected]);
   
    useEffect(() => {
        setPage(0)
        if(tag_type?.name !=='Any'){

            fetchMoreData(tag_type?.name)
        }
        else{
            fetchMoreData()
        }
    }, [tag_type])
    
    useEffect(() => {
        if(selectMostUsed){
        const uniqueArray = [...new Set([...selected,...selectMostUsed])];
        // console.log(uniqueArray)
            setSelected(uniqueArray)
            setItems(prevItems => prevItems.map((obj) => {
    
                if (selectMostUsed.some(x => x.id === obj.id))
                    obj.isShow = true
    
                return obj
    
            }));
        }

    }, [selectMostUsed]);


    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);

        // if (term && items.some(item => item.name.toLowerCase().includes(term))) {
        //     const filteredItems = items.filter(item =>
        //         item.name.toLowerCase().includes(term)
        //     );
        //     setSearchedItems(filteredItems);
        // } 
        // else if (term) {
        //     if (hasMore) {

        //         clearTimeout(searchTimeout);

        //         searchTimeout = setTimeout(() => {
        //             performSearch(term);
        //         }, 500);
        //     }
        // } 
        if (term) {
            // Check if there's an exact match in the loaded items
            const exactMatch = items.find(item => item.name.toLowerCase() === term);
    
            if (exactMatch) {
                setSearchedItems([exactMatch]);
                setloading(false)
            } else {
                // No exact match found in loaded data, perform search
                setloading(true)
                if (hasMore) {
                    clearTimeout(searchTimeout);
                    searchTimeout = setTimeout(() => {
                        performSearch(term);
                    }, 1000);
                }
            }
        }
        else {
            setSearchedItems([]);
        }
    };

    const performSearch = (searchTerm) => {
      
        searchAPI(searchTerm, 0, itemsPerPage).then((result) => {
            if (result.status) {
                const modifiedData = result?.data.data.map(item => ({
                    ...item,
                    isShow: selected.some(selectedItem => selectedItem.id === item.id)
                }));
                setSearchedItems(modifiedData);
                setloading(false)
            }
        });
    };

    const fetchMoreData = (tagtype) => {
        if(tagtype){
            fetchData(0, itemsPerPage,null,tagtype).then((result) => {
                if (result.status) {
                    if (result?.data.data?.length === 0) {
                        setHasMore(false);
                    }
                    //  else {
                        const modifiedData = result?.data.data.map(item => ({
                            ...item,
                            isShow: selected.some(selectedItem => selectedItem.id === item.id)
                        }));
                        setItems(modifiedData);
                        // console.log(modifiedData)
                        // setPage(page + 1);
                    // }
                }
            }).catch((error) => {
                console.error("Error fetching data: ", error);
            });
        }
        else{

            if (selctedItems) {
    
                fetchData(page, itemsPerPage, selctedItems).then((result) => {
                    if (result.status) {
                        if (result?.data.data?.length === 0) {
                            setHasMore(false);
                        }
                        else {
                            const modifiedData = result?.data.data.map(item => ({
                                ...item,
                                isShow: selected.some(selectedItem => selectedItem.id === item.id)
                            }));
                            // console.log(modifiedData)
                            setItems(prevItems => [...prevItems, ...modifiedData]);
                            setPage(page + 1);
    
                        }
                       
                        if (selctedItems && page === 0) {
                            const filteredObjects = result?.data.data?.filter(item => selctedItems.includes(item.id));
    
                            setSelected(filteredObjects)
    
                        }
                    }
                }).catch((error) => {
                    console.error("Error fetching data: ", error);
                });
            }
            else {
                fetchData(page, itemsPerPage).then((result) => {
                    if (result.status) {
                        if (result?.data.data?.length === 0) {
                            setHasMore(false);
                        } else {
                            const modifiedData = result?.data.data.map(item => ({
                                ...item,
                                isShow: selected.some(selectedItem => selectedItem.id === item.id)
                            }));
                            setItems(prevItems => [...prevItems, ...modifiedData]);
                            setPage(page + 1);
                        }
                    }
                }).catch((error) => {
                    console.error("Error fetching data: ", error);
                });
            }
        }
    };

    useEffect(() => {
        fetchMoreData();
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === ' ') {
            event.stopPropagation(); // Prevents the space key from propagating
        }
    };
    const checkOpen = (open) => {
        if (!open) {
            setSearchTerm('')
            setTimeout(() => {
                searchInputRef.current.focus();
            }, 100);
        }

    }
    const clearField = (e) => {
        if (e) {
            e.stopPropagation();
        }
        setSelected([]);

        const updatedItems = items.map(item => ({
            ...item,
            isShow: false,
        }));

        setItems(updatedItems);
        setSearchedItems(updatedItems);
        if(selctedItems){

            setSelectMostUsed([])
        }
    }
    // Ref to access the clearField function
    useImperativeHandle(ref, () => ({
        clearField: clearField
    }));

    return (
        <div>

            <Menu as="div" className="relative inline-block text-left w-full">
                {({ open }) => (
                    <>
                        <Menu.Button
                            onClick={() => checkOpen(open)}
                            className="flex justify-center items-center bg-whiteGrey h-14 w-full rounded-lg outline-none p-3 dropdown-menu-btn"
                        >
                            <span className='flex-1 line-clamp-1 text-left'>
                                {
                                    selected?.length === 0 && <span className='text-[#9EA1A8]'>Select...</span>
                                }
                                {selected.map((item) => (
                                    <span key={item.id}>{item.name}, </span>
                                ))}

                            </span>
                            <div className='flex items-center'>
                                {
                                    selected.length > 0 && <span
                                        onClick={clearField}
                                        className='px-2'><XIcon className="h-5 w-5 text-[#9EA1A8]" /></span>
                                }
                                <span aria-hidden="true" className={`${open ? 'rotate-180' : ''}`}>
                                    <DropdownIcon className='w-3' />
                                </span>
                            </div>
                        </Menu.Button>
                        <Menu.Items className="z-10 absolute w-full right-0 mt-2 origin-top-right rounded-md bg-whiteGrey shadow-lg ring-1 ring-black/5 focus:outline-none">
                            <input
                                ref={searchInputRef}
                                value={searchTerm}
                                onChange={handleSearch}
                                onKeyDown={handleKeyDown}
                                className='outline-none p-2 w-full' type="text" placeholder={placeholder} name="" id="" />
                            {searchTerm !== '' ? (
                                loading ?
                                <div className='text-center'>
                               Loading...
                            </div>
                            :
                             <>
                             {
                                   searchedItems.length > 0 ?
                                   <div className='max-h-[200px] overflow-y-auto'>
                                       {
                                           searchedItems.filter(
                                               (item, index, array) => array.findIndex(obj => obj.id === item.id) === index
                                           ).map((item) => (
                                               <div key={item.id} className='flex gap-2 p-2'>
                                                   <input
                                                       onChange={(event) => handleCheckbox(event, item.id, item)}
                                                       name={`item-${item.id}`}
                                                       type="checkbox"
                                                       checked={item.isShow}
                                                       id={`item-${item.id}`}
                                                   />
                                                   <label className='flex-1 cursor-pointer' htmlFor={`item-${item.id}`}>
                                                       {item.name}
                                                   </label>
                                               </div>
                                           ))
                                       }
                                   </div>
                                   :
                                   <div className='text-center'>
                                       No Data found
                                   </div>
                             }
                             </>
                            ) : (

                                <InfiniteScroll
                                    dataLength={items.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={<h4 className='px-2'>Loading...</h4>}
                                    height={200}
                                // endMessage={
                                //     <p style={{ textAlign: "center" }}>
                                //         <b>Yay! You have seen it all</b>
                                //     </p>
                                // }
                                >
                                    {items.filter(
                                        (item, index, array) => array.findIndex(obj => obj.id === item.id) === index
                                    ).map((item) => (
                                        <div key={item.id} className='flex gap-2 p-2'>
                                            <input
                                                onChange={(event) => handleCheckbox(event, item.id, item)}
                                                name={`item-${item.id}`}
                                                type="checkbox"
                                                checked={item.isShow}
                                                id={`item-${item.id}`}
                                            />
                                            <label className='flex-1 cursor-pointer' htmlFor={`item-${item.id}`}>
                                                {item.name}
                                            </label>
                                        </div>
                                    ))}
                                </InfiniteScroll>
                            )}
                        </Menu.Items>
                    </>
                )}
            </Menu>
        </div>
    )
})

export default PaginateMultiSelect;
