import React from 'react'
import Header from '../../components/header/Header'
import Banner from '../../components/banner/Banner'
import Footer from '../../components/footer/Footer'

function LivePage() {
    return (
        <>
            <Header />
            <Banner title={'Live'} />
            <section id='live-section' className='px-2 xs:px-5 pt-4 pb-[40px] md:pt-8 md:pb-[60px]'>
                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
                <iframe
                            src="https://player.castr.com/live_15abdbe045ac11ee8352fb65e09b1a09"
                            width="100%"
                            title='live-screen'
                            style={{ aspectRatio: '16/9', minHeight: '340px' }}
                            frameBorder="0"
                            scrolling="no"
                            allow="autoplay"
                            allowFullScreen
                            webkitAllowFullScreen
                            mozAllowFullScreen
                            oAllowFullScreen
                            msAllowFullScreen
                        ></iframe>
                </div>
            </section>
           <Footer/>
        </>
    )
}

export default LivePage