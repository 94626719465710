import React from 'react'
import { Disclosure } from '@headlessui/react'
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../header/Header';
import Banner from '../../banner/Banner';
import Footer from '../../footer/Footer';
import arrowUp from '../../../assets/arrowUp.svg'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BookMarkIcon, BookMarkIconActive } from '../../../assets/icon-file';
import { AddbookMark, RemovebookMark } from '../../../service/service';
import { ColorRing } from 'react-loader-spinner';
function AllLatestClasses() {
    const userData = useSelector((state) => state.userdata.userdata)
    const navigate = useNavigate();
    const location = useLocation();
    const classdata = location.state ? location.state.data : null;
    const [isLogedin, setIsLogedIn] = useState(false)
    const [data, setData] = useState([])
    const [bookmarkLoading, setBookmarkLoading] = useState({ loadingId: null });
    useEffect(() => {
        window.scrollTo(0, 0);
        setData(classdata)
        if (userData) {

            setIsLogedIn(true)
        }
        else {
            setIsLogedIn(false)
        }
    }, [])

    const OpenClass = (obj) => {

        const data = obj;
        navigate(`/${data.custom_permalink ?? data.slug}`);
    }

    function toggleIsMarked(itemId) {
        const updatedData = data.map(item => {
            if (item.id === itemId) {
                const data = {
                    class_id: item.id
                }
                setBookmarkLoading({ loadingId: item.id })
                if (item.is_marked === 1) {

                    RemovebookMark(data).then((result) => {
                        if (result.status) {
                            setBookmarkLoading({ loadingId: null })
                        }
                    })
                    return { ...item, is_marked: 0 };
                } else {

                    AddbookMark(data).then((result) => {
                        if (result.status) {
                            setBookmarkLoading({ loadingId: null })
                        }
                    })
                    return { ...item, is_marked: 1 };
                }
            }
            return item;
        });

        setData(updatedData);
    }
    return (
        <>
            <Header />
            <Banner title={'Latest Classes'} />
            {

                <>
                    {
                        data ?
                            <>
                                <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                                    <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                                        <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                                            {
                                                data?.map((obj) => (

                                                    <Disclosure key={obj.id}>
                                                        {({ open }) => (
                                                            <>
                                                                <div className='rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                                                    <div
                                                                        onClick={() => OpenClass(obj)}
                                                                        className=' cursor-pointer  pl-3 sm:pl-6 flex items-center gap-x-2 sm:gap-x-4'>
                                                                        <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] bg-whiteGrey flex items-center justify-center rounded-lg'>
                                                                            <span>
                                                                                <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                                                    <g clipPath="url(#clip0_326_587)">
                                                                                        <path d="M25.3333 20V5.33334C25.3324 5.13306 25.2864 4.93557 25.1987 4.75552C25.111 4.57548 24.9838 4.4175 24.8267 4.29334C24.6718 4.16803 24.4908 4.07903 24.2969 4.03289C24.1031 3.98674 23.9014 3.98461 23.7067 4.02667L11.7067 6.69334C11.4104 6.76014 11.1459 6.92607 10.9568 7.16368C10.7676 7.40128 10.6653 7.69633 10.6667 8V19.12C9.75645 18.6903 8.73143 18.5673 7.74539 18.7695C6.75934 18.9717 5.86545 19.4882 5.19779 20.2414C4.53014 20.9947 4.12473 21.9441 4.04238 22.9473C3.96004 23.9505 4.20519 24.9533 4.74107 25.8054C5.27695 26.6574 6.07467 27.3127 7.01454 27.673C7.95441 28.0333 8.98576 28.0791 9.95388 27.8036C10.922 27.5281 11.7747 26.9461 12.384 26.1449C12.9933 25.3437 13.3264 24.3665 13.3333 23.36C13.3471 23.1291 13.3471 22.8976 13.3333 22.6667V9.06667L22.6667 7V16.4533C21.7559 16.0234 20.7303 15.9005 19.7438 16.1031C18.7572 16.3058 17.8631 16.823 17.1956 17.5772C16.528 18.3313 16.1232 19.2817 16.0419 20.2855C15.9606 21.2894 16.2071 22.2925 16.7445 23.1443C17.2819 23.996 18.0812 24.6505 19.0222 25.0093C19.9632 25.3681 20.9953 25.4119 21.9634 25.1342C22.9314 24.8564 23.7833 24.2721 24.3909 23.4689C24.9986 22.6657 25.3293 21.6871 25.3333 20.68C25.346 20.4535 25.346 20.2265 25.3333 20Z" fill="#161E2E" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_326_587">
                                                                                            <rect width="32" height="32" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-primaryDark flex-1'>
                                                                            <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title}</div>
                                                                            {
                                                                                obj.block?.map((item) => (
                                                                                    <div key={item.id} >
                                                                                        {
                                                                                            item.type === 'Paragraph' &&

                                                                                            <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'> {item.description}</div>

                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex justify-end items-center gap-x-3 pr-3 sm:pr-6 outline-none">
                                                                        {
                                                                            isLogedin &&

                                                                            <>
                                                                                {
                                                                                    bookmarkLoading.loadingId === obj.id ?
                                                                                        <div>
                                                                                            <ColorRing
                                                                                                visible={true}
                                                                                                height="30"
                                                                                                width="30"
                                                                                                ariaLabel="blocks-loading"
                                                                                                wrapperStyle={{}}
                                                                                                wrapperClass="blocks-wrapper"
                                                                                                colors={['#186CCE', '#186CCE', '#186CCE', '#186CCE', '#186CCE']}
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        <span
                                                                                            onClick={() => toggleIsMarked(obj.id)}
                                                                                            className='cursor-pointer '
                                                                                        >

                                                                                            {

                                                                                                obj.is_marked == 0 ?

                                                                                                    <BookMarkIcon className='w-[20px] h-[20px] sm:w-[24px] sm:h-[24px]' />
                                                                                                    :
                                                                                                    <BookMarkIconActive className='w-[20px] h-[20px] sm:w-[24px] sm:h-[24px]' />
                                                                                            }
                                                                                        </span>
                                                                                }
                                                                            </>
                                                                        }
                                                                        <Disclosure.Button >
                                                                        {
                                                                                obj.block?.map((item) => (
                                                                                    <div key={item.id} >
                                                                                        {
                                                                                            item.type === 'Paragraph' &&

                                                                                            <img
                                                                                            src={arrowUp}
                                                                                            className={`${open ? 'rotate-180 transform' : ''
                                                                                                } h-5 w-5`}
                                                                                        />

                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                          

                                                                        </Disclosure.Button>
                                                                    </div>
                                                                </div>

                                                                <Disclosure.Panel className="pb-6text-primaryDark px-2 sm:px-4">

                                                                    {
                                                                        obj.block?.map((item) => (
                                                                            <div key={item.id} >

                                                                                {
                                                                                    item.type === 'Paragraph' &&
                                                                                    <>
                                                                                        <div className='text-lg sm:text-xl font-bold'>
                                                                                            Description:
                                                                                        </div>
                                                                                        <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal]'> {item.description}</div>
                                                                                    </>


                                                                                }
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </Disclosure.Panel>
                                                            </>
                                                        )}
                                                    </Disclosure>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </section>

                            </>
                            :
                            <div className='text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]'>
                                No Data Found
                            </div>
                    }
                </>
            }
            <Footer />
        </>
    )
}

export default AllLatestClasses