import React, { useState } from 'react'
import img from '../../../assets/donation.png';
import Swal from 'sweetalert2';
import { GetAudianceOptions, UserSubscribe } from '../../../service/service';
import { useNavigate } from 'react-router-dom';
import SubscribeModal from '../categorysection/SubscribeModal';
function DonationSection() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [emailList, setEmailList]=useState([])
    const [selected, setSelected] = useState([])
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission (e.g., send email to backend)
        // console.log('Subscribed with email:', email);
        setLoading(true)
        GetAudianceOptions().then((result) => {
            setLoading(false)
            // if(result.status){
                const filteredArray = result.data?.filter(item => item.audience_name === "Inside Chassidus");
                const newData= filteredArray?.map(item=>{
                    return {
                        label: item.audience_name,
                        value:item.id
                    }
                })
                setEmailList(newData)
                setIsOpen(true)
            // }
        })
       

    };

    const submitSubscribe = (event) => {
        event.preventDefault();
        const data={
            email,
            id:selected.map(item => item.value)
        }
        // console.log(data)
        setLoading(true)
        setIsOpen(false)
        UserSubscribe(data).then((result)=>{
            setLoading(false)
           
            if(result.status){
                setEmail('')
                Swal.fire({
                    icon: 'success',
                    title: "Subscribed Successfully.",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: result.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch((error)=>{
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: error.message,
                showConfirmButton: false,
                timer: 2000
            })
        })
    }
    return (
        <>
            <section id='donation-section' className='px-2 xs:px-5 py-8 lg:py-[60px] bg-[#F2F2F2]'>
                <div className="2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto">
                    <div className="flex items-center justify-between md:space-x-6">

                        <div className='w-1/2 hidden md:block'>
                            <img src={img} alt="Donation" className="w-full" />
                        </div>
                        <div className='md:w-1/2 space-y-[30px]'>
                            <div className='text-center md:text-right space-y-3'>
                                <h2 className='text-2xl lg:text-4xl font-extrabold leading-[normal]'>
                                    Your Support Matters
                                </h2>
                                <p className='text-sm font-normal leading-6 sm:text-lg md:font-semibold md:leading-7'>
                                    Help us continue to bring you new and exciting content and classes by supporting Inside chassidus with a tax-deductible donation
                                </p>
                            </div>
                            <img src={img} alt="Donation" className="w-full md:hidden" />
                            <div className='mt-10'>
                                <button
                                    type="button"
                                    onClick={()=>navigate('/donations')}
                                    className="mx-auto md:mx-[unset] md:ml-auto flex gap-x-1 h-10 lg:h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-6 md:px-8 py-1.5 text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                >
                                    Donate today
                                    <span aria-hidden="true" className='text-[13px]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z" fill="#F4FBF7" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
            <section id='subscription-section' className='px-2 xs:px-5 py-10 lg:py-[60px]'>
                <div className="2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto">
                    <div className='px-4 py-6 lg:py-[50px] text-whiteGrey rounded-3xl lg:rounded-[32px] bg-primaryBlue'>

                        <div className=' max-w-[555px] mx-auto text-center'>
                            <div className='mb-3 lg:mb-10'>
                                <div className='text-2xl leading-[28.5px] lg:text-4xl font-extrabold lg:leading-[normal] mb-3'>
                                    Subscribe to our newsletter
                                </div>
                                <span className='text-sm font-normal leading-[25.5px] sm:text-[18px] lg:font-semibold lg:leading-7'>
                                    Never miss any class, live session and updates. Join our club and get notified of every activity.
                                </span>
                            </div>
                            <form onSubmit={handleSubmit} className="flex flex-col xs:flex-row justify-between gap-2 lg:gap-4">
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    className="py-3 px-4 border rounded-3xl h-[40px] sm:h-[48px] text-primaryDark  outline-none w-full text-base font-normal"
                                    required
                                />
                                <div>
                                    <button disabled={loading}  type="submit" className="bg-primaryDark flex items-center text-whiteGrey text-base sm:text-lg md:text-base lg:text-lg font-semibold leading-7 px-6 py-3 sm:px-8 sm:py-[14px] md:px-4 md:py-3 lg:px-8 lg:py-[14px] rounded-3xl h-[40px] sm:h-[48px] disabled:opacity-50">
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </section>
            <SubscribeModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                emailList={emailList}
                handleSubmit={submitSubscribe}
                loading={loading}
                selected={selected}
                setSelected={setSelected}
            />
        </>
    )
}

export default DonationSection