import React, { useEffect, useState } from 'react'
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import Header from '../../header/Header';
import Banner from '../../banner/Banner';
import { WeeklyParsha } from '../../../service/service';
import Footer from '../../footer/Footer';


function ParshaOfTheWeek() {

    const navigate = useNavigate();
    const [data, setdata] = useState({});
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    
        // Optionally, you can also add a listener for navigation changes
        const handleScrollToTop = () => {
          window.scrollTo(0, 0);
        };
    
        // Attach the listener to the "beforeunload" event
        window.addEventListener('beforeunload', handleScrollToTop);
    
        // Clean up the listener when the component unmounts
        return () => {
          window.removeEventListener('beforeunload', handleScrollToTop);
        };
      }, []);
    useEffect(() => {
        setLoading(true)
        WeeklyParsha().then((result) => {
            setLoading(false)
           if(result.status){
            setdata(result.data)
           }
        })
        .catch((error)=>{
            console.log(error)
        })
    }, [])



    const OpenCategory = (obj) => {

        const data = obj;
        navigate(`/${data?.slug}`);

    }
    return (

        <>
            <Header />
            <Banner title={data?.title ?? data.name} description={data?.description} />
            {
                loading ?
                    <div className='flex justify-center min-h-[40vh]'>
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                        />
                    </div>
                    :
                    <>
                        {
                            data ?
                                <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                                    <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                                       
                                        <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                                                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                                                    <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>

                                                        <div onClick={() => OpenCategory(data)} className='cursor-pointer rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                                            <div className='pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                                                <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] bg-whiteGrey flex items-center justify-center rounded-lg'>
                                                                    <span>
                                                                        <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                                                            <path d="M5.89655 15H36.3103C37.0786 15 37.8153 15.3052 38.3585 15.8484C38.9017 16.3916 39.2069 17.1283 39.2069 17.8965V35.2758C39.2069 36.0441 38.9017 36.7808 38.3585 37.324C37.8153 37.8672 37.0786 38.1724 36.3103 38.1724H5.89655C5.12834 38.1724 4.39159 37.8672 3.84838 37.324C3.30517 36.7808 3 36.0441 3 35.2758V17.8965C3 17.1283 3.30517 16.3916 3.84838 15.8484C4.39159 15.3052 5.12834 15 5.89655 15Z" fill="#161E2E" />
                                                                            <path d="M26.9943 29.2576V21.0024C26.9938 20.8896 26.9662 20.7785 26.9138 20.6771C26.8613 20.5758 26.7853 20.4869 26.6913 20.417C26.5986 20.3465 26.4903 20.2964 26.3744 20.2704C26.2585 20.2444 26.1378 20.2432 26.0213 20.2669L18.8437 21.7679C18.6665 21.8055 18.5082 21.8989 18.3951 22.0326C18.282 22.1663 18.2208 22.3324 18.2216 22.5033V28.7623C17.6771 28.5205 17.064 28.4512 16.4743 28.565C15.8845 28.6788 15.3498 28.9695 14.9504 29.3935C14.5511 29.8175 14.3086 30.3519 14.2593 30.9165C14.2101 31.4812 14.3567 32.0457 14.6772 32.5252C14.9978 33.0048 15.4749 33.3737 16.0371 33.5765C16.5993 33.7793 17.2162 33.805 17.7952 33.65C18.3743 33.4949 18.8843 33.1673 19.2488 32.7163C19.6132 32.2654 19.8125 31.7154 19.8166 31.1488C19.8249 31.0189 19.8249 30.8886 19.8166 30.7586V23.1037L25.3993 21.9405V27.2614C24.8545 27.0194 24.241 26.9502 23.651 27.0642C23.0609 27.1783 22.526 27.4694 22.1268 27.8939C21.7275 28.3184 21.4854 28.8533 21.4367 29.4183C21.3881 29.9834 21.5356 30.548 21.857 31.0274C22.1784 31.5068 22.6565 31.8752 23.2194 32.0772C23.7823 32.2791 24.3996 32.3038 24.9786 32.1474C25.5577 31.9911 26.0672 31.6622 26.4306 31.2101C26.7941 30.7581 26.9919 30.2072 26.9943 29.6404C27.0019 29.5129 27.0019 29.3851 26.9943 29.2576Z" fill="#D4DDE9" />
                                                                            <path d="M10.138 3.62068H31.8621C32.2462 3.62068 32.6146 3.77327 32.8862 4.04487C33.1578 4.31648 33.3104 4.68485 33.3104 5.06896C33.3104 5.45306 33.1578 5.82144 32.8862 6.09304C32.6146 6.36465 32.2462 6.51723 31.8621 6.51723H10.138C9.75387 6.51723 9.38549 6.36465 9.11389 6.09304C8.84228 5.82144 8.6897 5.45306 8.6897 5.06896C8.6897 4.68485 8.84228 4.31648 9.11389 4.04487C9.38549 3.77327 9.75387 3.62068 10.138 3.62068Z" fill="#161E2E" />
                                                                            <path d="M7.24149 9.4138H34.7587C35.1428 9.4138 35.5112 9.56639 35.7828 9.83799C36.0544 10.1096 36.207 10.478 36.207 10.8621C36.207 11.2462 36.0544 11.6146 35.7828 11.8862C35.5112 12.1578 35.1428 12.3104 34.7587 12.3104H7.24149C6.85738 12.3104 6.48901 12.1578 6.2174 11.8862C5.9458 11.6146 5.79321 11.2462 5.79321 10.8621C5.79321 10.478 5.9458 10.1096 6.2174 9.83799C6.48901 9.56639 6.85738 9.4138 7.24149 9.4138Z" fill="#161E2E" />
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                                <div className='text-primaryDark'>
                                                                    <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{data.name}</div>
                                                                    <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{data.description != null ? data.description : ''}</div>
                                                                </div>
                                                            </div>
                                                            <div className='flex items-center justify-center pr-4 sm:pr-9 gap-x-4 sm:gap-x-8'>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </section>

                                    </div>
                                </section>
                                :
                                <div className='text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]'>
                                    No Data Found
                                </div>
                        }
                    </>
            }
 <Footer />
        </>
    )
}

export default ParshaOfTheWeek