import React from 'react'
import Background from '../../components/signupLogin/Background'
import { useState } from 'react';

function ForgetPassword() {
    const [step, setStep] = useState(0);
  return (
    <>
    <Background 
    step={step}
    setStep={setStep}
    />
    </>
  )
}

export default ForgetPassword