import React from 'react';
import Header from '../../components/header/Header'
import { Link } from 'react-router-dom'
import Footer from '../../components/footer/Footer'

function DonationPage() {

    return (
        <>
            <Header />
            <section id='donations-section' className='px-2 xs:px-5 pt-6 pb-[32px] md:pt-8'>
                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
                    <h1 className='text-primaryDark leading-[normal] text-[22px] md:text-[40px] font-extrabold mb-2 md:mb-4 '>
                        Donate Now
                    </h1>
                    <div className='text-primaryDark text-lg font-semibold'>
                        <p>
                            If you do not wish to donate online, please <Link to='/contact-us' className='text-primaryBlue'>Contact Us</Link> and we will contact you with instructions on alternatives.
                        </p>
                        <p>
                            If you wish to join our Chai club, and partner with us by giving a monthly recurring donation, please select monthly below.
                        </p>
                        <p>
                            Have a wonderful day and thank you for supporting InsideChassidus.org
                        </p>
                    </div>


                </div>
            </section>
            <div id='donation-form-container'>
                <div className="keela-embed-form" data-src="https://give-usa.keela.co/embed/XfwzpxcvtX9Wvg3JX">
                    <div className="keela-loading">
                        <div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default DonationPage