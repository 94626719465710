import React, { useState } from 'react';
import { CodeVerify, UserForgetPass } from '../../service/service';
import { Link } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setUserdata } from '../../store/slices/UserDataSlice';
import OTPInput from 'react-otp-input';
import CreateNewPass from './CreateNewPass';
function ForgetForm({step, setStep}) {
    
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setloading] = useState(false);
    // const [showPassword, setShowPassword] = useState(false);
    const [otp, setOtp] = useState('');
    const [emailData, setEmailData]= useState({})
    const [formData, setFormData] = useState({
        email: '',
        is_admin: false
    });

    const [errors, setErrors] = useState({
        email: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.email) {
            newErrors.email = 'Email is required.';
        }



        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
       
        if (validateForm()) {
            setloading(true)
            UserForgetPass(formData)
                .then((result) => {

                    if (result.status) {

                        setFormData({
                            email: '',
                            is_admin: false
                        });
                        setErrorMsg('')
                        setEmailData(result.data)
                    setStep(1)
                        setloading(false)

                    }
                    else {
                        setErrorMsg(result.message)
                        setloading(false)
                    }

                })
                .catch((error) => {
                    console.log(error.message);
                    setErrorMsg(error.message)
                    setloading(false)
                });
        }
    };
    const handleVerify = (event) => {
        event.preventDefault();
        // console.log(emailData)
        const FormData = {
            code:otp,
            user_id:emailData?.id
        }
        setloading(true)
        CodeVerify(FormData)
            .then((result) => {

                if (result.status) {
                    setErrorMsg('')
                    // console.log(result.data);
                    setStep(2)
                    setloading(false)
                }
                else {
                    setErrorMsg(result.message)
                    setloading(false)
                }

            })
            .catch((error) => {
                console.log(error.message);
                setErrorMsg(error.message)
                setloading(false)
            });


    };
    // const handleTogglePassword = () => {
    //     setShowPassword((prevShowPassword) => !prevShowPassword);
    // };
    return (
        <>
            {
                step === 0 &&
                <form className="space-y-2" onSubmit={handleSubmit}>
                    {/* Email input */}
                    <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                        <label htmlFor="email" className="block text-sm font-normal leading-5 text-primaryDark">
                            Email address
                        </label>
                        <div className="">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                placeholder='johndoe@mail.com'
                                required
                                value={formData.email}
                                onChange={handleChange}
                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                            />
                        </div>
                    </div>
                    {errors.email && <p className="text-red-500 px-3">{errors.email}</p>}
                    {errorMsg !== "" && <p className="text-red-500 px-3">{errorMsg}</p>}


                    <div className='text-right text-xs xs:text-sm font-normal leading-5 text-whiteGrey md:text-primaryDark'>
                        Haven’t received an email yet?

                        <Link
                            className='pl-1 underline'
                        // to={'/forget-password'}
                        >
                            Send again
                        </Link>
                    </div>
                    {/* Submit button */}
                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className="flex w-full h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                        >
                            Send
                        </button>
                    </div>
                </form>
            }
            {
                step === 1 &&
                <form className="space-y-[14px]" onSubmit={handleVerify}>
                    <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span style={{ width: "16px" }}></span>}
                                renderInput={(props) => <input {...props} 
                                inputMode='numeric'
                                // onKeyDown={(e) => {
                                //     // Allow numeric keys and some control keys (e.g., arrow keys, backspace)
                                //     if (!(e.key >= '0' && e.key <= '9') && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                //       e.preventDefault();
                                //     }
                                //   }}
                                // onKeyDown={handleKeyPress}
                                // onPaste={handlePaste}
                                />
                                }
                                isInputNum={true}
                                placeholder='------'
                                inputStyle={{
                                  
                                    borderRadius: "8px",
                                    width: "45px",
                                    height: "45px",
                                    fontSize: "22px",
                                    color: "#000",
                                    fontWeight: "400",
                                    
                                    background:"rgb(210, 214, 220,0.4)",
                                    
                                  }}
                            />
                    {errorMsg !== '' && <p className="text-red-500 px-3">{errorMsg}</p>}
                    <div className='text-right text-sm font-normal leading-5 text-whiteGrey md:text-primaryDark'>
                        Haven’t received an email yet?

                        <Link
                            className='pl-1 underline'
                        // to={'/forget-password'}
                        >
                            Send again
                        </Link>
                    </div>
                    {/* Submit button */}
                    <div className='pt-[10px]'>
                        <button
                            type="submit"
                            disabled={loading}
                            className="flex w-full h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                        >
                            Verify
                        </button>
                    </div>
                </form>
            }
            {
                step===2 &&
                <CreateNewPass emailData={emailData}/>
            }
        </>
    );
}

export default ForgetForm;
