import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import BlockData from './BlockData'
// import { AddNewBlock } from './helperfunction'
export default function NewBlockModal({ isOpen, setIsOpen,AddNewBlock }) {
    function closeModal() {
        setIsOpen(false)
    }
    const AddBlock=(type)=>{
        AddNewBlock(type)
        setIsOpen(false)
    }
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[99999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[791px] transform  rounded-2xl bg-white px-8 pt-4 pb-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-bold leading-[normal] flex justify-between items-center text-primaryDark"
                                    >
                                        <div className='flex-grow text-center'>
                                            Add new block
                                        </div>
                                        <span
                                            onClick={closeModal}
                                            className='cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M1 13L13 1M1 1L13 13" stroke="#64748B" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </Dialog.Title>
                                    <div className='flex flex-wrap gap-6 mt-6'>
                                        {BlockData.map((obj, index) => (
                                            <div
                                            onClick={()=>AddBlock(obj.title)}
                                            key={'item-' + index} className='w-[31%] cursor-pointer rounded-xl border-[1px] border-primaryDark bg-primaryBlue bg-opacity-10 h-[132px] flex flex-col justify-center items-center gap-3'>
                                                <div dangerouslySetInnerHTML={{ __html: obj.icon }} />
                                                <div className='text-2xl font-bold leading-7 text-primaryDark'>
                                                    {obj.title}
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
