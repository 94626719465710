import React, { useState, useEffect } from 'react'
import DashboardSideNav from '../../adminComponents/DashboardComponents/DashboardSideNav'
import DashboardHeader from '../../adminComponents/DashboardComponents/DashboardHeader'
import { ThreeCircles } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import CategoriesTable from '../../adminComponents/CategoriesManagement/CategoriesTable'
import CategoriesModal from '../../adminComponents/CategoriesManagement/CategoriesModal'
import { AdminHeaderSearch, AdminListCategories, AdvanceTableSearch, CategoriesDropdown } from '../../service/service'
import { Menu } from '@headlessui/react'
import { FilterIcon } from '../../assets/icon-file'
function CategoriesManagement() {
    const userPermissions = useSelector((state) => state.adminUser.userPermissions)
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const [responseData, setResponseData] = useState([]);
    const [isEdit, setIsEdit] = useState(false)
    const [noData, setNoData] = useState('')
    const [nestedArray, setNestedArray] = useState([])
    const [DropdownOptions, setDropdownOptions] = useState([{
        id: null,
        name: 'None'
    }])

    const [formData, setFormData] = useState({
        name: '',
        slug: '',
        description: '',
        parent_category: {},
        permalink: ''
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        slug: '',
        description: '',
        parent_category: '',
        permalink: ''
    });
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [headCells, setHeadCells] = useState([
        {
            id: 'Name',
            isShow: true
        },
        {
            id: 'Slug',
            isShow: true
        },
        {
            id: 'Description',
            isShow: true
        },

    ])
    const [filterData, setFilterData] = useState({
        title: '',
        slug: '',
        description: ''
    })
    function handlePageChange(selectedPage) {
        setCurrentPage(selectedPage.selected);
    }
    const itemsPerPage = 10; // Number of items per page
    useEffect(() => {
        showDropdown()
    }, [])
    const showDropdown = () => {
        CategoriesDropdown(0,2000).then((result) => {
            setNestedArray(result.data)
            const flattenedData = flattenNestedArray(result.data);
            setDropdownOptions([{ index: 0, id: null, name: 'None', level: 0 }, ...flattenedData])
            // setDropdownOptions(flattenedData)
        })
    }
    function flattenNestedArray(arr) {
        const result = [];

        function flatten(obj, depth) {
            const index = depth.join('.');
            result.push({ index, id: obj.id, name: obj.name, level: depth.length - 1 });

            if (obj.children && obj.children.length > 0) {
                obj.children.forEach((child, childIndex) =>
                    flatten(child, depth.concat(childIndex + 1))
                );
            }
        }

        arr.forEach((item, index) => flatten(item, [index + 1]));

        return result;
    }

    function removeNodeById(data, targetId) {
        return data.reduce((acc, item) => {
            if (item.id === targetId) {
                // Exclude the node with the target ID
                return acc;
            } else {
                const children = item.children ? removeNodeById(item.children, targetId) : [];
                if (children.length > 0) {
                    // Include the item with updated children
                    acc.push({ ...item, children });
                } else {
                    // Include the item without children if they are all removed
                    acc.push({ ...item });
                }
            }
            return acc;
        }, []);
    }
    useEffect(() => {
        if(isSearching){
            HandleTableSearch()
          }
        else{

            showCategoriesList()
        }
    }, [currentPage, itemsPerPage])
    const showCategoriesList = (message, pagecount) => {
        setIsLoading(true);
        const pagenum = pagecount ?? currentPage;
        AdminListCategories(pagenum, itemsPerPage)
            .then((result) => {
                setIsLoading(false)
                if (result.status) {
                    setResponseData(result.data.data)
                    if (result?.data.data?.length === 0) {
                        setNoData('No data to show')
                    }
                    const totalPages = Math.ceil(result.data.total / itemsPerPage);
                    setTotalPages(totalPages)
                    if (message) {
                        Swal.fire({
                            icon: 'success',
                            title: message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error.message);
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            });
    }
    const EditCategory = (data) => {
        // console.log(nestedArray)
        const updatedArray = removeNodeById(nestedArray, data.id);
        const flattenedData = flattenNestedArray(updatedArray);
        setDropdownOptions([{ index: 0, id: null, name: 'None', level: 0 }, ...flattenedData])
        const foundObject = data?.parent_id ? DropdownOptions.find((item) => item.id === data?.parent_id) : { index: 0, id: null, name: 'None', level: 0 };
        setFormData(
            {
                id: data.id,
                name: data?.name,
                slug: data?.slug,
                description: data?.description,
                parent_category: foundObject,
                permalink: data?.custom_permalink
            }
        )
        setFormErrors({
            name: '',
            slug: '',
            description: '',
            parent_category: '',
            permalink: ''
        })
        setIsOpen(true)
        setIsEdit(true)
    }
    const addNewCategory = () => {
        showDropdown()
        setFormData(
            {
                name: '',
                slug: '',
                description: '',
                parent_category: { index: 0, id: null, name: 'None', level: 0 },
                permalink: ''
            }
        )
        setFormErrors({
            name: '',
            slug: '',
            description: '',
            parent_category: '',
            permalink: ''
        })
        setIsOpen(true)
        setIsEdit(false)
    }
    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        const index = parseInt(name.split('-')[1]); // Extracting the index from the name

        // Update the headCells state based on the checkbox change
        setHeadCells((prevHeadCells) =>
            prevHeadCells.map((item, idx) =>
                idx === index ? { ...item, isShow: checked } : item
            )
        );
    };
    const buttonElement =
      
        <div className='flex gap-x-4'>
            <Menu as="div" className="relative inline-block text-left">
                <Menu.Button
                    className="flex gap-x-2 px-5 h-11 items-center justify-center rounded-3xl border-[1px] border-primaryBlue hover:bg-opacity-[0.9] text-sm md:text-base font-normal leading-7 text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                    <span aria-hidden="true" className='text-[13px]'>
                        <FilterIcon />
                    </span>
                    Filter

                </Menu.Button>
                <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-2 py-1 space-y-1">
                        {headCells?.map((item, index) => (
                            <div key={item.id} className='flex gap-2'>
                                <input onChange={handleCheckbox} name={`item-${index}`} type="checkbox" checked={item.isShow} id={`item-${index}`} />
                                <label htmlFor={`item-${index}`}>{item.id}</label>
                            </div>
                        ))}
                    </div>
                </Menu.Items>
            </Menu>
            {
            userPermissions?.includes('add_category') &&
            <button
                type="button"
                onClick={addNewCategory}
                className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
                <span aria-hidden="true" className='text-[13px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                        <g clipPath="url(#clip0_966_2238)">
                            <path d="M10.9453 6.44531H7.05469V2.55469C7.05469 1.9722 6.58249 1.5 6 1.5C5.41751 1.5 4.94531 1.9722 4.94531 2.55469V6.44531H1.05469C0.472195 6.44531 0 6.91751 0 7.5C0 8.08249 0.472195 8.55469 1.05469 8.55469H4.94531V12.4453C4.94531 13.0278 5.41751 13.5 6 13.5C6.58249 13.5 7.05469 13.0278 7.05469 12.4453V8.55469H10.9453C11.5278 8.55469 12 8.08249 12 7.5C12 6.91751 11.5278 6.44531 10.9453 6.44531Z" fill="#F9F9F9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_966_2238">
                                <rect width="12" height="13" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                New Category
            </button>
            }

        </div>;

    const [searchTerm, setSearchTerm] = useState('');
    const performSearch = (searchTerm) => {

        AdminHeaderSearch('search-categories', searchTerm, currentPage, itemsPerPage)
            .then((result) => {
                setIsLoading(false)
                if (result.status) {
                    setResponseData(result.data.data)
                    if (result?.data.data?.length === 0) {
                        setNoData('No data to show')
                    }
                    const totalPages = Math.ceil(result.data.total / itemsPerPage);
                    setTotalPages(totalPages)

                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }

            })

    };
    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchTerm !== '') {
                performSearch(searchTerm);
            }
            if (searchTerm === '') {

                showCategoriesList()
            }
        }, 1000)

        return () => clearTimeout(getData)
    }, [searchTerm])
    const inputElement = <div className='hidden lg:flex justify-between items-center border-[1px] border-[##D2D6DC] rounded-3xl pl-5 pr-2 w-[272px] h-[44px]'>
        <input value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='bg-transparent outline-none' type="text" placeholder='Search' />
        <div className='flex items-center justify-center  rounded-full h-[31px] w-[31px] bg-primaryBlue'>
            <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
            </svg>
        </div>
    </div>
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const HandleTableSearch = () => {

        const updatedData = {
            ...filterData,
            per_page: itemsPerPage,
        };

        // console.log(updatedData);
        setIsSearching(true)
        AdvanceTableSearch('advance-search-categories', updatedData, isSearching?currentPage :0)
            .then((result) => {
                setIsLoading(false)
                if (result.status) {
                    setResponseData(result.data.data)
                    if (result?.data.data?.length === 0) {
                        setNoData('No data to show')
                    }
                    const totalPages = Math.ceil(result.data.total / itemsPerPage);
                    setTotalPages(totalPages)

                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }

            })

    }
    const ResetAdvanceSearch = () => {
        setFilterData({
            title: '',
            slug: '',
            description: ''
        })
        showCategoriesList()
        setIsSearching(false)
    }
    return (
        <>
            {
                isLoading &&
                <div className="overlay">
                    <div className="loaderImage-container">
                        <ThreeCircles
                            height="100"
                            width="100"
                            color="#F9F9F9"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor=""
                            innerCircleColor=""
                            middleCircleColor=""
                        />
                    </div>
                </div>
            }
            <div className='bg-whiteGrey'>
                <DashboardSideNav />
                {
                    isOpen ?
                        <CategoriesModal
                            isOpen={isOpen} setIsOpen={setIsOpen}
                            setResponseData={setResponseData}
                            formData={formData} setFormData={setFormData} isEdit={isEdit}
                            DropdownOptions={DropdownOptions}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            setTotalPages={setTotalPages}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                        />
                        :
                        <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
                            <DashboardHeader title={'Classes'} headerButton={buttonElement} subtitle={'Categories'} Headerinput={inputElement} />
                            <div className='px-10 pt-8 flex flex-wrap gap-4 '>
                                <div className='flex flex-col gap-4 flex-1 text-base font-semibold'>
                                    <label>
                                        Title
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={filterData.title}
                                        className='bg-whiteGrey h-14 rounded-lg outline-none p-3' placeholder='Category name' type="text" name="title" />
                                </div>
                                <div className='flex flex-col gap-4 flex-1 text-base font-semibold'>
                                    <label>
                                        Slug
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={filterData.slug}
                                        className='bg-whiteGrey h-14 rounded-lg outline-none p-3' placeholder='Slug' type="text" name="slug" />
                                </div>
                                <div className='flex flex-col gap-4 flex-1 text-base font-semibold'>
                                    <label>
                                        Description
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={filterData.description}
                                        className='bg-whiteGrey h-14 rounded-lg outline-none p-3' placeholder='Description' type="text" name="description" />
                                </div>

                                <div className='flex items-end pb-[6px]'>
                                    <button
                                        type="button"
                                        onClick={HandleTableSearch}
                                        className="flex gap-x-2 w-[137px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                    >
                                        Search
                                        <span aria-hidden="true" className='text-[13px]'>
                                            <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div className='flex items-end pb-[6px]'>
                                    <button
                                        type="button"
                                        onClick={ResetAdvanceSearch}
                                        className="flex gap-x-2 px-8 h-11 items-center justify-center rounded-3xl border-[1px] border-primaryBlue hover:bg-opacity-[0.9] text-sm md:text-base font-normal leading-7 text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                            <CategoriesTable
                                responseData={responseData}
                                // setResponseData={setResponseData}
                                EditCategory={EditCategory}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                showCategoriesList={showCategoriesList}
                                noData={noData}
                                setIsLoading={setIsLoading}
                                handlePageChange={handlePageChange}
                                userPermissions={userPermissions}
                                headCells={headCells}
                            />
                        </main>
                }
            </div>

        </>
    )
}

export default CategoriesManagement