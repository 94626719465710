import React from 'react'
import Background from '../../components/signupLogin/Background'

function Signin() {
  return (
    <>
    <Background/>
    </>
  )
}

export default Signin